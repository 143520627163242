import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] | any = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: 'dashboard'
    },
    {
        id: 'operational-planning',
        title: 'Operational Planning',
        type: 'collapsable',
        icon: 'OP',
        children: [
            {
                id: 'configuration',
                title: 'Configuration',
                type: 'collapsable',
                icon: 'settings',
                children: [
                    // {
                    //     id      : 'item',
                    //     title   : 'Item',
                    //     type    : 'item',
                    //     icon    : 'widgets',
                    //     url     : '/config/items'
                    // },
                    // {
                    //     id      : 'product',
                    //     title   : 'Product',
                    //     type    : 'item',
                    //     icon    : 'P',
                    //     url     : '/config/products'
                    // },
                    {
                        id: 'carrier-type',
                        title: 'Carrier Type',
                        type: 'item',
                        icon: 'language',
                        url: '/config/carrier-types',
                        permission: 'read_carrier-type',
                    },
                    {
                        id: 'airline',
                        title: 'Airline',
                        type: 'item',
                        icon: 'business',
                        url: '/config/airlines',
                        permission: 'read_airline',
                    },
                    {
                        id: 'currency',
                        title: 'Currency',
                        type: 'item',
                        icon: 'attach_money',
                        url: '/config/currencies',
                        permission: 'read_currency',
                    },
                    {
                        id: 'country',
                        title: 'Country',
                        type: 'item',
                        icon: 'flag',
                        url: '/config/countries',
                        permission: 'read_country',
                    },
                    {
                        id: 'timezone',
                        title: 'Timezone',
                        type: 'item',
                        icon: 'location_city',
                        url: '/config/timezones',
                        permission: 'read_timezone',
                    },
                    // {
                    //     id: 'city',
                    //     title: 'City',
                    //     type: 'item',
                    //     icon: 'location_city',
                    //     url: '/config/cities',
                    //     permission: 'read_city',
                    // },
                    {
                        id: 'language',
                        title: 'Language',
                        type: 'item',
                        icon: 'language',
                        url: '/config/languages',
                        permission: 'read_language',
                    },

                    
                    // {
                    //     id: 'endorsement',
                    //     title: 'Endorsement',
                    //     type: 'item',
                    //     icon: 'location_city',
                    //     url: '/config/endorsements',
                    //     permission: 'read_endorsement',
                    // },
                    {
                        id: 'messages',
                        title: 'Messages',
                        type: 'collapsable',
                        icon: 'mail',
                        children: [
                            {
                                id: 'message-text',
                                title: 'Message Text',
                                type: 'item',
                                icon: 'attach_money',
                                url: '/config/message-texts',
                            },
                            {
                                id: 'message-text-translation',
                                title: 'Message Text Translation',
                                type: 'item',
                                icon: 'attach_money',
                                url: '/config/message-text-translations',
                            },
                            {
                                id: 'message-configuration',
                                title: 'Data Transmission',
                                type: 'item',
                                icon: 'attach_money',
                                url: '/config/message-configurations',
                            },
                        ]
                    },
                    {
                        id: 'operator',
                        title: 'Operator',
                        type: 'item',
                        icon: 'local_atm',
                        url: '/config/operators',
                        permission: 'read_operator',
                    },
                    // {
                    //     id: 'product-category',
                    //     title: 'Product Category',
                    //     type: 'item',
                    //     icon: 'language',
                    //     url: '/config/product-categories',
                    // },
                    // {
                    //     id: 'product',
                    //     title: 'Product',
                    //     type: 'item',
                    //     icon: 'language',
                    //     url: '/config/products',
                    // },
                    // {
                    //     id: 'aircaft-types',
                    //     title: 'Aircraft Types',
                    //     type: 'item',
                    //     icon: 'flight',
                    //     url: '/config/aircraft_types',
                    // },
                    {
                        id: 'agent-types',
                        title: 'User Types',
                        type: 'item',
                        icon: 'flight',
                        url: '/config/agent-types',
                        permission: 'read_agent-type',
                    },
                    {
                        id: 'payment-gateway',
                        title: 'Payment Gateway',
                        type: 'item',
                        icon: 'local_atm',
                        url: '/config/payment-gateways',
                        permission: 'read_payment-gateway',
                    },
                ],
            },
            {
                id: 'seats',
                title: 'Aircraft Management',
                type: 'collapsable',
                icon: 'flight',
                children: [
                    // {
                    //     id: 'seats',
                    //     title: 'Seats',
                    //     type: 'item',
                    //     icon: 'airline_seat_recline_normal',
                    //     url: '/op/seats',
                    // },
                    {
                        id: 'aircaft-types',
                        title: 'Aircraft Types',
                        type: 'item',
                        icon: 'AT',
                        url: '/config/aircraft_types',
                        permission: 'read_aircraft-type',
                    },
                    {
                        id: 'cabin-classes',
                        title: 'Cabin Classes',
                        type: 'item',
                        icon: 'CC',
                        url: '/op/cabin_classes',
                        permission: 'read_cabin-class',
                    },
                    // {
                    //     id: 'aircaft-images',
                    //     title: 'Aircraft Images',
                    //     type: 'item',
                    //     icon: 'AI',
                    //     url: '/op/aircraft_images',
                    // },
                    {
                        id: 'cabins',
                        title: 'Seat Configuration',
                        type: 'item',
                        icon: 'SC',
                        url: '/op/seat_configuration',
                        permission: 'read_cabin',
                    },
                    
                    {
                        id: 'aircrafts',
                        title: 'Aircraft',
                        type: 'item',
                        icon: 'AC',
                        url: '/op/aircrafts',
                        permission: 'read_aircraft',
                    },
                    
                ],
            },
            {
                id: 'destinations',
                title: 'Destinations',
                type: 'collapsable',
                icon: 'place',
                children: [
                    {
                        id: 'city',
                        title: 'Cities',
                        type: 'item',
                        icon: 'location_city',
                        url: '/config/cities',
                        permission: 'read_city',
                    },
                    {
                        id: 'airports',
                        title: 'Airports',
                        type: 'item',
                        icon: 'flight',
                        url: '/op/airports',
                        permission: 'read_airport',
                    },
                    

                ]

            },
            // {
            //     id: 'aircrafts',
            //     title: 'Aircrafts',
            //     type: 'collapsable',
            //     icon: 'flight',
            //     children: [
            //         {
            //             id: 'aircaft-types',
            //             title: 'Aircraft Types',
            //             type: 'item',
            //             icon: 'flight',
            //             url: '/config/aircraft_types',
            //             permission: 'read_aicraft-type',
            //         },
            //         {
            //             id: 'aircrafts',
            //             title: 'Aircraft',
            //             type: 'item',
            //             icon: 'flight',
            //             url: '/op/aircrafts',
            //             permission: 'read_aircraft',
            //         }
            //     ]
            // },
            

            
            {
                id: 'booking-clusters',
                title: 'Booking Clusters',
                type: 'item',
                icon: 'airline_seat_legroom_normal',
                url: '/op/booking_clusters',
                permission: 'read_booking-cluster',
            },
            // {
            //     id: 'agents',
            //     title: 'Agents',
            //     type: 'item',
            //     icon: 'person',
            //     url: '/op/agents',
            //     permission: 'read_agent',
            // },
            {
                id: 'sale-locations',
                title: 'Sales Locations',
                type: 'item',
                icon: 'place',
                url: '/op/sales_locations',
                permission: 'read_sales-location',
            },
            // {
            //     id: 'fare-rules',
            //     title: 'Fare Rules',
            //     type: 'item',
            //     icon: 'attach_money',
            //     url: '/op/fare_rules'
            //     permission: 'read_fare-rule',
            // },
            {
                id: 'taxes',
                title: 'Tax',
                type: 'item',
                icon: 'monetization_on',
                url: '/op/taxes',
                permission: 'read_tax',
            },
            
            // {
            //     id      : 'inventorys',
            //     title   : 'Inventory',
            //     type    : 'item',
            //     icon    : 'data_usage',
            //     url     : '/op/flight-inventory'
            //     permission: 'read_xx',
            // },
            {
                id: 'product-management',
                title: 'Product Management',
                type: 'collapsable',
                icon: 'store',
                children: [
                    {
                        id: 'product',
                        title: 'Product',
                        type: 'item',
                        icon: 'store',
                        url: '/config/products',
                        permission: 'read_product',
                    },
                    {
                        id: 'product-category',
                        title: 'Product Category',
                        type: 'item',
                        icon: 'language',
                        url: '/config/product-categories',
                        permission: 'read_product-category',
                    }
                ],
            },
            {
                id: 'flight-management',
                title: 'Flight Management',
                type: 'collapsable',
                icon: 'F',
                children: [
                    {
                        id: 'flight-shedules',
                        title: 'Flight Schedule',
                        type: 'item',
                        icon: 'schedule',
                        url: '/op/schedules',
                        permission: 'read_schedule',
                    },
                    {
                        id: 'flight-connection',
                        title: 'Flight Connection',
                        type: 'item',
                        icon: 'attach_money',
                        url: '/config/flight-connections',
                        permission: 'read_flight-connection',
                    },
                ]
            },
            {
                id: 'fares-managements',
                title: 'Fare Management',
                type: 'item',
                icon: 'attach_money',
                url: '/op/fares',
                permission: 'read_airline',
            },
            {
                id: 'dcs-profiles',
                title: 'DCS Profile',
                type: 'collapsable',
                icon: 'place',
                children: [
                    {
                        id: 'zpl-designs',
                        title: 'ZPL Design',
                        type: 'item',
                        icon: 'flight',
                        url: '/op/dcs_profiles/zpl_designs',
                        permission: 'read_zpl-design',
                    },
                    {
                        id: 'profiles',
                        title: 'Profiles',
                        type: 'item',
                        icon: 'location_city',
                        url: '/op/dcs_profiles',
                        permission: 'read_zpl-profile',
                    },

                ]

            },
            {
                id: 'time-limits',
                title: 'Time Limit',
                type: 'item',
                icon: 'schedule',
                url: '/op/time_limits',
                permission: 'read_time-limit',
            },
            // {
            //     id: 'calendar',
            //     title: 'Flight Watch',
            //     type: 'item',
            //     icon: 'today',
            //     url: 'dcs/calendar'
            // },
        ],
    },
    {
        id: 'booking-and-ticketing',
        title: 'Booking & Ticketing',
        type: 'item', //collapsable
        // permission: 'access_bt',
        icon: 'BT',
        url: 'bt/dashboard',
        permission: 'read_booking-and-ticketing',
        // children: [
        //     {
        //         id: "overbook",
        //         title: "Overbooking",
        //         type: "item",
        //         icon: "description",
        //         url: "/bat/overbooking",
        //     },
        //     {
        //         id: "wait-list",
        //         title: "Wait List",
        //         type: "item",
        //         icon: "list",
        //         url: "/bat/wait-list",
        //     },
            // {
            //     id: 'booking-ui',
            //     title: 'Old Booking UI',
            //     type: 'item',
            //     icon: 'update',
            //     url: 'ui/booking-ui',
            // },
            // {
            //     id: 'dcs-ui',
            //     title: 'Test DCS UI',
            //     type: 'item',
            //     icon: 'web',
            //     url: 'ui/dcs-ui',
            // },
            // {
            //     id: 'bt1',
            //     title: 'Coming soon..',
            //     type: 'item',
            //     icon: 'hourglass_empty',
            //     url: '#',
            // },
            // {
            //     id: 'calendar',
            //     title: 'Flight Watch',
            //     type: 'item',
            //     icon: 'today',
            //     url: 'dcs/calendar'
            // },
            // {
            //     id: 'printer-configuration',
            //     title: 'Printer Configuration',
            //     type: 'item',
            //     icon: 'print',
            //     url: 'dcs/printer-configurations'
            // },
        // ],
    },
    // // {
    // //     id: 'departure-control',
    // //     title: 'Departure Control',
    // //     type: 'collapsable',
    // //     icon: 'DC',
    // //     children: [
    // //         {
    // //             id: 'departure',
    // //             title: 'Departure',
    // //             type: 'item',
    // //             icon: 'D',
    // //             url: 'dc/departures',
    // //         },
    // //         {
    // //             id: 'dc1',
    // //             title: 'Comming soon..',
    // //             type: 'item',
    // //             icon: 'hourglass_empty',
    // //             url: '#',
    // //         },
    // //         {
    // //             id: 'go-show',
    // //             title: 'Go show',
    // //             type: 'item',
    // //             icon: 'attach_money',
    // //             url: '/dc/departures/go-shows/new',
    // //         },
    // //         {
    // //             id: 'no-rec',
    // //             title: 'No Rec',
    // //             type: 'item',
    // //             icon: 'attach_money',
    // //             url: '/dc/departures/no-recs/new',
    // //         },
    // //         {
    // //             id: 'block-seat',
    // //             title: 'Block Seat',
    // //             type: 'item',
    // //             icon: 'attach_money',
    // //             url: '/dc/departures/block-seats',
    // //         },
    // //         {
    // //             id: 'manifest',
    // //             title: 'Manifest',
    // //             type: 'item',
    // //             icon: 'attach_money',
    // //             url: '/dc/departures/manifests',
    // //         },
    // //         {
    // //             id: 'bag-tag',
    // //             title: 'Bag Tag',
    // //             type: 'item',
    // //             icon: 'business',
    // //             url: '/dc/departures/bag-tags',
    // //         },
    // //         {
    // //             id: 'no-show-report',
    // //             title: 'No Show Report',
    // //             type: 'item',
    // //             icon: 'attach_money',
    // //             url: '/dc/departures/no-show-report',
    // //         },
    // //         {
    // //             id: 'reservation-payment',
    // //             title: 'Reservation Payment',
    // //             type: 'item',
    // //             icon: 'attach_money',
    // //             url: '/dc/departures/reservation-payments/new',
    // //         },
    // //     ],
    // // },

    {
        id: 'departure-control-system',
        title: 'Departure Control',
        type: 'item',
        icon: 'DC',
        url: 'dcs',
        permission: 'read_departure-control',
    },
    {
        id: 'user-security',
        title: 'User Management',
        type: 'collapsable',
        icon: 'security',
        children: [
            // {
            //     id   : 'modules',
            //     title: 'Modules',
            //     type : 'item',
            //     icon : 'view_module',
            //     url  : '/security/modules'
            // },
            {
                id: 'roles',
                title: 'Roles',
                type: 'item',
                icon: 'work',
                url: '/security/roles',
                permission: 'read_role',
            },
            {
                id   : 'permissions',
                title: 'Permissions',
                type : 'item',
                icon : 'pan_tool',
                url  : '/security/permissions',
                permission: 'read_permission',
            },
            {
                id   : 'clients',
                title: 'Clients',
                type : 'item',
                icon : 'laptop',
                url  : '/security/clients',
                permission: 'read_client',
            },
            {
                id   : 'users',
                title: 'Users',
                type : 'item',
                icon : 'person',
                url  : '/security/users',
                permission: 'read_user',
            },
            {
                id: 'checks',
                title: 'Checks',
                type: 'item',
                icon: 'pan_tool',
                url: '/security/checks',
                permission: 'read_check',
            },
        ],
    },
    {
        id: 'hq-report',
        title: 'Report',
        type: 'collapsable',
        icon: 'report',
        children: [
            // {
            //     id: 'project-dashboard',
            //     title: 'Projects Sample',
            //     type: 'item',
            //     icon: 'work',
            //     url: '/dashboards/projects',
            // },
            // {
            //     id   : 'analytics-dashboard',
            //     title: 'Analytics Sample',
            //     type : 'item',
            //     icon : 'pan_tool',
            //     url  : '/projects/analytics'
            // },
            {
                id: 'hq-sales',
                title: 'Sales',
                type: 'item',
                icon: 'work',
                url: '/reports/hq/sales',
                permission: 'read_sales-report',
            },
            // {
            //     id   : 'hq-aircrafts',
            //     title: 'Aircrafts',
            //     type : 'item',
            //     icon : 'pan_tool',
            //     url  : '/reports/hq/aircraft'
            // },
            {
                id   : 'hq-passengers',
                title: 'Passengers',
                type : 'item',
                icon : 'person',
                url  : '/reports/hq/passenger',
                permission: 'read_passenger-report',
            },
            // {
            //     id   : 'hq-financials',
            //     title: 'Summary',
            //     type : 'item',
            //     icon : 'pan_tool',
            //     url  : '/bat/report'
            // },
            // {
            //     id   : 'counter-financial',
            //     title: 'Counter Sales',
            //     type : 'item',
            //     icon : 'pan_tool',
            //     url  : '/reports/counter/financial'
            // },
            // {
            //     id   : 'counter-passengers',
            //     title: 'Counter Passengers',
            //     type : 'item',
            //     icon : 'person',
            //     url  : '/reports/counter/passenger'
            // }
        ],
    },
    // {
    //     id: 'message-configuration',
    //     title: 'Data Transmission',
    //     type: 'item',
    //     icon: 'attach_money',
    //     url: '/config/data-transmission',
    // },
    {
        id: 'broadcast',
        title: 'Messaging',
        type: 'item',
        icon: 'speaker',
        url: '/config/data-transmission',
        permission: 'read_data-transmission',
    },
];
