import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../user.model';
import { RolesDropdownService } from '../../roles/roles.service';
import { takeUntil } from 'rxjs/operators';
import { AclService } from 'app/services/acl.service';
import { Dropdown } from 'app/services/dropdown.model';
import { SaleLocationsDropdownService } from 'app/main/op/sales-locations/sale-locations.service';
import { AgentTypesDropdownService } from 'app/main/system-configurations/agent-types/agent-types.service';
import { GtoolsService } from 'app/services/gtools.service';
import { UsersService } from '../users.service';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
    selector     : 'users-user-form-dialog',
    templateUrl  : './user-form.component.html',
    styleUrls    : ['./user-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UsersUserFormDialogComponent
{
    action: string;
    user: User;
    rolesDropdown: Dropdown[];
    userForm: FormGroup;
    dialogTitle: string;
    officesDropdown: Dropdown[];
    agentTypesDropdown: Dropdown[];

    constraintViolationList: any;
    indexedConstraintViolation = {};

    /**
     * Constructor
     *
     * @param {MatDialogRef<UsersUserFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UsersUserFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private usersService: UsersService,
        private rolesDropdownService: RolesDropdownService,
        private saleLocationsDropdownService: SaleLocationsDropdownService,
        private agentTypesDropdownService: AgentTypesDropdownService,
        private authenticationService: AuthenticationService,
        public aclService: AclService,
        public gTools: GtoolsService,
    )
    {
        this.rolesDropdownService.onRolesDropdownChanged
            // .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(rolesDropdown => {
                this.rolesDropdown = rolesDropdown;
                // console.log('this.rolesDropdown',this.rolesDropdown)
            });
            
        this.saleLocationsDropdownService.onSaleLocationsDropdownChanged
            // .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(officesDropdown => {
                this.officesDropdown = officesDropdown;
            });

        this.agentTypesDropdownService.onAgentTypesDropdownChanged
            // .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(agentTypesDropdown => {
                this.agentTypesDropdown = agentTypesDropdown;
            });
            
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit User';
            this.user = _data.user;
            this.userForm = this.createUserForm();
            this.userForm.get('email').disable();
        }
        else  if ( this.action === 'profile' )
        {
            this.dialogTitle = 'User Profile';
            this.user = _data.user;
            this.userForm = this.createUserForm();
            this.userForm.get('email').disable();
            this.userForm.get('isEnabled').disable();
            this.userForm.get('moduleRoles').disable();
            this.userForm.get('salesLocation').disable();
            this.userForm.get('agentType').disable();
            this.userForm.get('notes').disable();
        } else
        {
            this.dialogTitle = 'New User';
            this.user = new User({});
            this.userForm = this.createUserForm();
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create user form
     *
     * @returns {FormGroup}
     */
    createUserForm(): FormGroup
    {
        // return this._formBuilder.group(this.user);
        return this._formBuilder.group({
            id          : [this.user.id],
            firstName   : [this.user.firstName, [Validators.required,Validators.minLength(2),Validators.maxLength(30)]],
            lastName    : [this.user.lastName, [Validators.required,Validators.minLength(2),Validators.maxLength(30)]],
            avatar      : [this.user.avatar],
            company     : [this.user.company, [Validators.maxLength(50)]],
            salesLocation: [this.user.salesLocation],
            // agent: [this.user.agent],
            agentType   : [this.user.agentType],
            jobTitle    : [this.user.jobTitle, [Validators.maxLength(50)]],
            email       : [this.user.email, [Validators.required, Validators.email]],
            // password    : [this.user.password],
            phone       : [this.user.phone, [Validators.pattern(/^(\+?\d{1,3}[- ]?)?\d{10}$/)]],
            birthDate   : [this.user.birthDate, []],
            address     : [this.user.address, [Validators.minLength(3), Validators.maxLength(255)]],
            notes       : [this.user.notes,[Validators.maxLength(500)]],
            moduleRoles : [this.user.moduleRoles.map(role=>role.iri)],
            isEnabled   : [this.user.isEnabled],
        });
    }

    onSave(){
        switch ( this.action )
        {

             case 'new':
                // this.loading = true;
                this.constraintViolationList=null;
                this.indexedConstraintViolation = {};

                this.usersService.createUser(this.userForm.getRawValue())
                    .then(user=>{
                        //UserForm=createUserForm...
                        this.user = user;
                        this.createUserForm();
                        this.action = "edit";
                        this.dialogTitle = "Edit User";
                        this.matDialogRef.close();
                    })
                    .catch(err=>{
                        this.gTools.handleServerError(err,this.userForm);
                    })
                    .finally(()=>{
                        // this.loading=false;
                    })
                break;

            /**
             * Update
             */
            case 'edit':
                let changedForm = this.gTools.getChangedFormFields(this.userForm);

                // this.loading = true;
                this.constraintViolationList=null;
                this.indexedConstraintViolation = {};

                this.usersService.updateUser(changedForm.getRawValue())
                    .then(user=>{
                        this.user = user;
                        this.createUserForm();
                        this.action = "edit";
                        this.dialogTitle = "Edit User";
                        this.matDialogRef.close();
                    })
                    .catch(err=>{
                        this.gTools.handleServerError(err,this.userForm);
                    })
                    .finally(()=>{
                        // this.loading=false;
                    })

            break;

            /**
             * Profile Update
             */
            case 'profile':

                // this.loading = true;
                this.constraintViolationList=null;
                this.indexedConstraintViolation = {};

                this.usersService.updateUserProfile(this.gTools.getChangedFormFields(this.userForm).getRawValue())
                    .then(user=>{
                        this.user = user;
                        this.createUserForm();
                        this.action = "edit";
                        this.dialogTitle = "Edit User";
                        // this.authenticationService.user();
                        this.matDialogRef.close();
                    })
                    .catch(err=>{
                        this.gTools.handleServerError(err,this.userForm);
                    })
                    .finally(()=>{
                        // this.loading=false;
                    })

            break;
        }
    }


    compareFn(a: any, b: any): boolean {
        // console.log('a',a);
        // console.log('b',b);
        return a === b.iri;
    }
}
