import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Role } from './role.model';
import { environment } from 'environments/environment';
import { Dropdown } from 'app/services/dropdown.model';
import { Paginator } from 'app/main/shared/pagination/paginator.model';

@Injectable()
export class RolesService implements Resolve<any>
{
    onRolesChanged: BehaviorSubject<any>;
    onPaginatorChanged: BehaviorSubject<any>=new BehaviorSubject(null);
    onSelectedRolesChanged: BehaviorSubject<any>;
    // onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    roles: Role[];
    // user: any;
    selectedRoles: number[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private rolesDropdownService: RolesDropdownService,
    )
    {
        // Set the defaults
        this.onRolesChanged = new BehaviorSubject([]);
        this.onSelectedRolesChanged = new BehaviorSubject([]);
        // this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRoles(),
                // this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getRoles('?simplesearch='+searchText);
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getRoles();
                    });

                    resolve(files);

                },
                reject
            );
        });
    }

    /**
     * Get roles
     *
     * @returns {Promise<any>}
     */
    getRoles(search=''): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serverURL}/api/roles${search}`)
                .subscribe((response: any) => {

                        let roles = response["hydra:member"];
                        this.onPaginatorChanged.next(new Paginator(response["hydra:view"], this, response["hydra:totalItems"],'getRoles',roles.length));

                        // .. search
                        // if ( this.searchText && this.searchText !== '' )
                        // {
                        //     roles = FuseUtils.filterArrayByString(roles, this.searchText);
                        // }

                        this.roles = roles.map(role => {
                            return new Role(role);
                        });

                        this.onRolesChanged.next(this.roles);
                        resolve(this.roles);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected role by id
     *
     * @param id
     */
    toggleSelectedRole(id): void
    {
        // First, check if we already have that role as selected...
        if ( this.selectedRoles.length > 0 )
        {
            const index = this.selectedRoles.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedRoles.splice(index, 1);

                // Trigger the next event
                this.onSelectedRolesChanged.next(this.selectedRoles);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedRoles.push(id);

        // Trigger the next event
        this.onSelectedRolesChanged.next(this.selectedRoles);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedRoles.length > 0 )
        {
            this.deselectRoles();
        }
        else
        {
            this.selectRoles();
        }
    }

    /**
     * Select roles
     *
     * @param filterParameter
     * @param filterValue
     */
    selectRoles(filterParameter?, filterValue?): void
    {
        this.selectedRoles = [];

        // If there is no filter, select all roles
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedRoles = [];
            this.roles.map(role => {
                this.selectedRoles.push(role.id);
            });
        }

        // Trigger the next event
        this.onSelectedRolesChanged.next(this.selectedRoles);
    }

    /**
     * Create role
     *
     * @param role
     * @returns {Promise<any>}
     */
    createRole(role): Promise<any>
    {
        // console.log('creating role');
        return new Promise((resolve, reject) => {

            this._httpClient.post(environment.serverURL+'/api/roles', {...role})
                .subscribe(response => {
                    this.getRoles();
                    this.rolesDropdownService.getRolesDropdown();
                    let role = new Role(response);
                    resolve(role);
                },reject);
        });
    }

    /**
     * Update role
     *
     * @param role
     * @returns {Promise<any>}
     */
    updateRole(role): Promise<any>
    {
        // console.log('updating role');
        return new Promise((resolve, reject) => {

            this._httpClient.patch(environment.serverURL+'/api/roles/' + role.id, {...role})
                .subscribe(response => {
                    this.getRoles();
                    this.rolesDropdownService.getRolesDropdown();
                    let role = new Role(response);
                    resolve(role);
                },reject);
        });
    }

    /**
     * Deselect roles
     */
    deselectRoles(): void
    {
        this.selectedRoles = [];

        // Trigger the next event
        this.onSelectedRolesChanged.next(this.selectedRoles);
    }

    /**
     * Delete role
     *
     * @param role
     * @returns {Promise<any>}
     */
    deleteRole(role): Promise<any>
    {
        // const roleIndex = this.roles.indexOf(role);
        // this.roles.splice(roleIndex, 1);

        let roleId = role;
        if ( typeof role === 'object' && role.id){
            roleId = role.id;
        }
        //////
        return new Promise((resolve, reject) => {
            // console.log(role);

            this._httpClient.delete(environment.serverURL+'/api/roles/' + roleId )
                .subscribe(response => {
                    this.getRoles();
                    this.rolesDropdownService.getRolesDropdown();

                    // this.onRolesChanged.next(this.roles);
                    resolve(response);
                },reject);
        });
                //////
        // this.onRolesChanged.next(this.roles);
    }

    /**
     * Delete selected roles
     */
    deleteSelectedRoles(): void
    {
        for ( const roleId of this.selectedRoles )
        {
            // const role = this.roles.find(_role => {
            //     return _role.id === roleId;
            // });
            // const roleIndex = this.roles.indexOf(role);
            // this.roles.splice(roleIndex, 1);

            this.deleteRole(roleId);
        }
        // this.onRolesChanged.next(this.roles);
        this.deselectRoles();
    }

}

@Injectable()
export class RolesDropdownService implements Resolve<any>
{
    onRolesDropdownChanged: BehaviorSubject<any>;

    rolesDropdown: Dropdown[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onRolesDropdownChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRolesDropdown(),
            ]).then(
                ([files]) => {

                    // TODO: try to check if Server sent Event roles-updated socket
                    // this.onSearchTextChanged.subscribe(searchText => {
                    //     this.searchText = searchText;
                    //     this.getRoles();
                    // });


                    resolve(files);

                },
                reject
            );
        });
    }

    /**
     * Get roles
     *
     * @returns {Promise<any>}
     */
    getRolesDropdown(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get( environment.serverURL+'/api/roles/dropdown')
                    .subscribe((response: any) => {

                        const rolesDropdown = response["hydra:member"];

                        this.rolesDropdown = rolesDropdown.map((role:any) => {
                            return new Dropdown(role["@id"],role.name,role);
                        });

                        this.onRolesDropdownChanged.next(this.rolesDropdown);
                        resolve(this.rolesDropdown);
                    }, reject);
            }
        );
    }
}
