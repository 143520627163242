import { ReportMenuCategoriesComponent } from './report-menu-categories/report-categories.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule, Routes } from "@angular/router";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { FuseSidebarModule } from "@fuse/components";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ChartsModule } from "ng2-charts";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CounterPassengerReportComponent } from "./counter-passenger-report/counter-passenger-report.component";
import { CounterFinancialReportComponent } from "./counter-financial-report/counter-financial-report.component";
import { HqPassengerReportComponent } from "./hq-passenger-report/hq-passenger-report.component";
import { HqAircraftReportComponent } from "./hq-aircraft-report/hq-aircraft-report.component";
import { HqSalesDashboardComponent } from "./hq-sales-dashboard/hq-sales-dashboard.component";
import { ReportsComponent } from "./reports.component";
import { PdfGeneratorService } from 'app/services/pdf-generator.service';
import { ReportsService } from "./services/reports.service";
import { CabinClassesDropdownService } from "../op/cabin-classes/cabin-classes.service";
import { SaleLocationsDropdownService } from "../op/sales-locations/sale-locations.service";
import { FuseSharedModule } from "@fuse/shared.module";
import { BookingsReportComponent } from './hq-booking-report/hq-booking-report.component';
import { BaggagesReportComponent } from './hq-baggage-report/hq-baggage-report.component';
import { FaresReportComponent } from './hq-fare-report/hq-fare-report.component';
import { FlightsReportComponent } from './hq-flight-report/hq-flight-report.component';
import { PaymentsReportComponent } from './hq-payment-report/hq-payment-report.component';
import { StationsReportComponent } from './hq-station-report/hq-station-report.component';
import { SalesReportComponent } from './hq-sale-report/hq-sale-report.component';
import { ExportService } from 'app/services/export.service';
import { ClientsDropdownService } from '../security/clients/clients.service';
import { NgxPrintModule } from 'ngx-print';

const routes: Routes = [
    {
        path: 'reports',
        component: ReportsComponent,
        children: [
          { path: 'hq/booking-report', component: BookingsReportComponent },
          { path: 'hq/flight-report', component: FlightsReportComponent },
          { path: 'hq/payment-report', component: PaymentsReportComponent },
          { path: 'hq/fare-report', component: FaresReportComponent },
          { path: 'hq/sale-report', component: SalesReportComponent },
          { path: 'hq/baggage-report', component: BaggagesReportComponent },
          { path: 'hq/station-report', component: StationsReportComponent },
          { path: 'hq/aircraft', component: HqAircraftReportComponent },
          { path: 'hq/passenger', component: HqPassengerReportComponent },
          { path: 'hq/sales', component: HqSalesDashboardComponent },
          { path: 'counter/passenger', component: CounterPassengerReportComponent },
          { path: 'counter/financial', component: CounterFinancialReportComponent },
          { path: '', redirectTo: '/hq/sales', pathMatch: 'full' },
        ],
        resolve: {
            CabinClassesDropdownService,
            SaleLocationsDropdownService,
            ClientsDropdownService
        },
    },
    
];

@NgModule({
    declarations: [
        CounterPassengerReportComponent,
        CounterFinancialReportComponent,
        HqPassengerReportComponent,
        HqAircraftReportComponent,
        HqSalesDashboardComponent,
        ReportsComponent,
        ReportMenuCategoriesComponent,
        BookingsReportComponent,
        FlightsReportComponent,
        PaymentsReportComponent,
        FaresReportComponent,
        SalesReportComponent,
        BaggagesReportComponent,
        StationsReportComponent
    ],
    entryComponents: [],

    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        MatIconModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatTableModule,
        MatToolbarModule,
        MatMenuModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgxChartsModule,
        FuseSharedModule,
        FuseSidebarModule,
        ChartsModule,
        NgxPrintModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: [
        ReportsService,
        ExportService,
        CabinClassesDropdownService,
        SaleLocationsDropdownService,
        ClientsDropdownService,
        PdfGeneratorService
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ReportsModule { }
