import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from "@fuse/animations";
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  animations: [fuseAnimations],
})
export class ReportsComponent implements OnInit {

  constructor(
    private _fuseSidebarService: FuseSidebarService
  ) { }

  ngOnInit() {
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
