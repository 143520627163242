import { FuseUtils } from '@fuse/utils';
import { City } from 'app/main/system-configurations/city/city.model';
import { Operator } from '../fares/fare.model';

export class SaleLocation
{
    iri: string;
    id: number;
    name: string;
    code: string;
    address1: string;
    address2: string;
    address3: string;
    // postCode: string;
    // activatePosting: boolean;
    // postingPeriod: number;
    // lastDate: string;
    // creditLimit: number;
    commission: number;
    // queueCity: City;
    // queueNumber: number;
    salesCity: City;
    // operator: Operator;

    /**
     * Constructor
     *
     * @param saleLocation
     */
    constructor(saleLocation?)
    {
        saleLocation = saleLocation || {};
        this.iri = saleLocation['@id'] || null;
        this.id             = saleLocation.id || null;//FuseUtils.generateGUID();
        this.name           = saleLocation.name || null;
        this.code           = saleLocation.code || null;
        this.address1       = saleLocation.address1 || null;
        this.address2       = saleLocation.address2 || null;
        this.address3       = saleLocation.address3 || null;
        // this.postCode      = saleLocation.post_code || null;
        // this.activatePosting = saleLocation.activate_posting || false;
        // this.postingPeriod = saleLocation.posting_period || null;
        // this.lastDate      = saleLocation.last_date || null;
        // this.creditLimit   = saleLocation.credit_limit || null;
        this.commission     = saleLocation.commission || 0;
        // this.queueCity     = new City(saleLocation.queue_city || {});
        // this.queueNumber   = saleLocation.queue_number || null;
        this.salesCity     = new City(saleLocation.sales_city || {});
        // this.operator       = new Operator(saleLocation.operator || {});
    }

}
