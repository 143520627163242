import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { Observable, EMPTY } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthenticationService, public router: Router, private _snackBar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('Intercepted by TokenInterceptor');
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`,
                ContentType:  'application/json',
                AccessControlAllowHeaders: 'Content-Type',
                AccessControlAllowMethods: '*',
                AccessControlAllowOrigin: '*'

            }
        });
        
        // Skip token validation and proceed with the login request
        if (request.url.includes(environment.authURI) || request.url.includes('/auth/')) {
        // if (request.url.includes('/pages/auth/') || request.url.includes('/auth/')) {
            return next.handle(request);
        }

        // const isApiRequest = request.url.includes('/api/');


        if(request.responseType == "text"){ //skip svg images. 
            return next.handle(request);
        }

        // check TokenExpiration Time
        if (this.auth.isTokenExpired() ) {
            // todo: check http only
            // this.auth.logout();
            let url: string = this.router.routerState.snapshot.url;
            this.router.navigate(['/pages/auth/login-2',{ redirectUrl: url }]);
            return EMPTY;  // Prevents the request from being sent
        }

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
                // console.log('http response', event);
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                
                // if (err.status === 0) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Server Unreachable, Check Your Internet Connection. (0 Unknown Error)');
                //     this._snackBar.open('Server Unreachable, Check Your Internet Connection', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
                // if (err.status === 301) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Update Requested Resource URL. (301 Moved Permanently)');
                //     this._snackBar.open('Update Requested Resource URL', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
                // if (err.status === 400) {
                //     // redirect to the login route
                //     // or show a modal
                //     // console.log('Authentication Credentials Invalid, Invalid username and password combination (400 Bad Request)');
                //     this._snackBar.open('Invalid username and password combination', null, {
                //         duration: 10000,
                //     });
                //     this.auth.logout();
                //     this.router.navigate(['/pages/auth/login-2']);
                // }
                if (err.status === 401) {
                    // redirect to the login route
                    // or show a modal
                    // console.log('Authentication Credentials Invalid (401 Unauthorized)');
                    // this._snackBar.open('Authentication Credentials Invalid', null, {
                    //     duration: 10000,
                    // });
                    // this.auth.logout();
                    let url: string = this.router.routerState.snapshot.url;
                    this.router.navigate(['/pages/auth/login-2',{ redirectUrl: url }]);
                }
                // if (err.status === 403) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Access Denied. (403 Forbidden)');
                //     this._snackBar.open('Access Denied', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['/']);
                // }
                // if (err.status === 404) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Requested Resource Doesn\'t Exist. (404 Not Found)');
                //     this._snackBar.open('Requested Resource Doesn\'t Exist', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
                // if (err.status === 405) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Use Appropriate Request Method (GET,POST,PUT,PATCH,DELETE). (405 Method Not Allowed)');
                //     this._snackBar.open('Use Appropriate Request Method (GET,POST,PUT,PATCH,DELETE)', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
                // if (err.status === 408) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Server took too long to respond. (408 Request Timeout)');
                //     this._snackBar.open('Server took too long to respond', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
                // if (err.status === 500) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Server encountered an error while processing your request. (500 Internal Server Error)');
                //     this._snackBar.open('Server encountered an error while processing your request', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
                // if (err.status === 503) {
                //     // redirect to the previous route
                //     // or show a modal
                //     // console.log('Server Not Responding. (503 Service Unavailable)');
                //     this._snackBar.open('Server Not Responding', null, {
                //         duration: 10000,
                //     });
                //     // this.router.navigate(['error-404']);
                // }
            }
        }));
    }
}