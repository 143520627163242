import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Paginator } from './paginator.model';

@Component({
    selector   : 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls  : ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy
{

    // Private
    @Input() paginator: Paginator;
    @Input() isNumbered: boolean=true;

    /**
     * Constructor
     *
     */
    constructor(
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }


    onClickPaginate(page: string){
        // console.log(page);
        window.alert('page: '+page);
    }
}
