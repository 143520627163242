import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Aircraft, HqAircraftReportResp } from '../models/hqreports';
import { ReportsService } from '../services/reports.service';
import { ExportService } from './../../../services/export.service';

// import DataLabelsPlugin from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-hq-aircraft-report',
  templateUrl: './hq-aircraft-report.component.html',
  styleUrls: ['../reports.theme.scss', './hq-aircraft-report.component.scss']
})
export class HqAircraftReportComponent implements OnInit {
  aircraftReport?: HqAircraftReportResp;
  salesAircraftReportData : any = [];
  reportsArray: any = [];
  totalPaxCount: any = 0;
  totalTrips : number = 0;
  totalSales: number = 0;
  aircrafts?: Aircraft[];
  // view: any[];
  width: number = 700;
  // totalSales: any;
  height: number = 300;
  fitContainer: boolean = false;

  view: any[] = [1000, 400];
  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Sales';
  timeline = true;
  doughnut = true;

  salesPieColorScheme = {
    domain: ['#5EDFC1', '#D84A59']
  };
  //pie
  showLabels = true;

  public salesPie = [
    {
      "name": "Profit",
      "value": 2243772
    },
    {
      "name": "Expenses",
      "value": 1126000
    }
  ];

  constructor(private reportsService: ReportsService) { }

  ngOnInit() {
    this.getSalesByFlightNumber();
    this.getSalesByAircraft();
    this.genAllReports();
  }

  // getHqAircraftReport() {
  //   console.log('HQ Aircraft Report Service Works');
  //   this.reportsService.GetHqAircraftReport()
  //       .subscribe((data: HqAircraftReportResp) =>{
  //         this.aircraftRreport = data;
  //         this.aircrafts = this.aircraftRreport.aircrafts;
  //         console.log('hq aircraft', this.aircrafts);
          
  //       });
  // }


  getSalesByFlightNumber() {
    this.reportsService.TotalSalesByFlightNumber()
        .subscribe((data) =>{
          if(data.success)
          // console.log(data);
          this.aircraftReport = data.data;
          this.reportsArray = data.data;
          this.totalPaxCount = data.data.map(item => item.pax_count).reduce(this.sumReducer, 0);
          this.totalSales = data.data.map(item => item.sales).reduce(this.sumReducer, 0);
          this.totalTrips = data.data.map(item => item.trips).reduce(this.sumReducer, 0);
          
        });
  }

  getSalesByAircraft() {
    this.reportsService.TotalSalesByAircraft()
        .subscribe((data) =>{
          if(data.success)
          // console.log(data);
          this.salesAircraftReportData = data.data;
          // console.log(this.salesAircraftReportData);
          
        });
  }

  genAllReports(){
    this.reportsService.genReportSummary().subscribe((data)=> {
      // console.log(data);
    })
  }

  sumReducer(sum, val) {
    return Number(sum) + Number(val);
  }




  }