import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent implements OnInit {

  @Input() data: any;
  @Input() key: any;
  
  constructor() { }

  ngOnInit() {
  }

  isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  getObjectKeys(obj: any): string[] {
    return this.isObject(obj) ? Object.keys(obj) : [];
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
}
