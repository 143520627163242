import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardComponent } from "./dashboard.component";
import { AuthGuard } from 'app/auth/auth.guard';
import { RouterModule } from '@angular/router';

const routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule { }
