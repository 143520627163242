import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { SaleLocation } from './sale-location.model';
import { environment } from 'environments/environment';
import { Dropdown } from 'app/services/dropdown.model';
import { Paginator } from 'app/main/shared/pagination/paginator.model';
import { AclService } from 'app/services/acl.service';

@Injectable()
export class SaleLocationsService implements Resolve<any>
{
    onSaleLocationsChanged: BehaviorSubject<any>;
    onPaginatorChanged: BehaviorSubject<any>=new BehaviorSubject(null);
    onSelectedSaleLocationsChanged: BehaviorSubject<any>;
    // onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    saleLocations: SaleLocation[];
    // user: any;
    selectedSaleLocations: number[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private saleLocationsDropdown: SaleLocationsDropdownService,
    )
    {
        // Set the defaults
        this.onSaleLocationsChanged = new BehaviorSubject([]);
        this.onSelectedSaleLocationsChanged = new BehaviorSubject([]);
        // this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getSaleLocations(),
                // this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getSaleLocations('?simplesearch='+searchText);
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getSaleLocations();
                    });

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get saleLocations
     *
     * @returns {Promise<any>}
     */
    getSaleLocations(search=''): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get(`${environment.serverURL}/api/sales_locations${search}`)
                    .subscribe((response: any) => {

                        this.saleLocations = response['hydra:member'];
                        this.onPaginatorChanged.next(new Paginator(response["hydra:view"], this, response["hydra:totalItems"],'getSaleLocations'));
                        
                        // console.log('sale-locations', this.saleLocations);
                        // .. search
                        // if ( this.searchText && this.searchText !== '' )
                        // {
                        //     this.saleLocations = FuseUtils.filterArrayByString(this.saleLocations, this.searchText);
                        // }

                        this.saleLocations = this.saleLocations.map(saleLocation => {
                            return new SaleLocation(saleLocation);
                        });

                        this.onSaleLocationsChanged.next(this.saleLocations);
                        resolve(this.saleLocations);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected saleLocation by id
     *
     * @param id
     */
    toggleSelectedSaleLocation(id): void
    {
        // First, check if we already have that saleLocation as selected...
        if ( this.selectedSaleLocations.length > 0 )
        {
            const index = this.selectedSaleLocations.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedSaleLocations.splice(index, 1);

                // Trigger the next event
                this.onSelectedSaleLocationsChanged.next(this.selectedSaleLocations);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedSaleLocations.push(id);

        // Trigger the next event
        this.onSelectedSaleLocationsChanged.next(this.selectedSaleLocations);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedSaleLocations.length > 0 )
        {
            this.deselectSaleLocations();
        }
        else
        {
            this.selectSaleLocations();
        }
    }

    /**
     * Select saleLocations
     *
     * @param filterParameter
     * @param filterValue
     */
    selectSaleLocations(filterParameter?, filterValue?): void
    {
        this.selectedSaleLocations = [];

        // If there is no filter, select all sale-locations
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedSaleLocations = [];
            this.saleLocations.map(saleLocation => {
                this.selectedSaleLocations.push(saleLocation.id);
            });
        }

        // Trigger the next event
        this.onSelectedSaleLocationsChanged.next(this.selectedSaleLocations);
    }

    /**
     * Update saleLocation
     *
     * @param saleLocation
     * @returns {Promise<any>}
     */
    createSaleLocation(saleLocation): Promise<any>
    {
        // console.log('creating sale location', saleLocation);
        return new Promise((resolve, reject) => {

            this._httpClient.post(environment.serverURL + '/api/sales_locations', {...saleLocation})
                .subscribe(response => {
                    this.getSaleLocations();
                    this.saleLocationsDropdown.getSaleLocationsDropdown();
                    resolve(response);
                },reject);
        });
    }

    /**
     * Update saleLocation
     *
     * @param saleLocation
     * @returns {Promise<any>}
     */
    updateSaleLocation(saleLocation): Promise<any>
    {
        // console.log('updating sale location', id, saleLocation);
        return new Promise((resolve, reject) => {

            this._httpClient.patch(environment.serverURL + '/api/sales_locations/' + saleLocation.id, {...saleLocation})
                .subscribe(response => {
                    this.getSaleLocations();
                    this.saleLocationsDropdown.getSaleLocationsDropdown();
                    resolve(response);
                },reject);
        });
    }

    /**
     * Deselect saleLocations
     */
    deselectSaleLocations(): void
    {
        this.selectedSaleLocations = [];

        // Trigger the next event
        this.onSelectedSaleLocationsChanged.next(this.selectedSaleLocations);
    }

    /**
     * Delete saleLocation
     *
     * @param saleLocation
     * @returns {Promise<any>}
     */
    deleteSaleLocation(saleLocation): Promise<any>
    {
        // const saleLocationIndex = this.saleLocations.indexOf(saleLocation);
        // this.saleLocations.splice(saleLocationIndex, 1);

        let saleLocationId = saleLocation;
        if ( typeof saleLocation === 'object' && saleLocation.id){
            saleLocationId = saleLocation.id;
        }
        //////
        return new Promise((resolve, reject) => {
            // console.log(saleLocation);

            this._httpClient.delete(environment.serverURL+'/api/sales_locations/' + saleLocationId )
                .subscribe(response => {
                    this.getSaleLocations();
                    this.saleLocationsDropdown.getSaleLocationsDropdown();
                    // this.onSaleLocationsChanged.next(this.saleLocations);
                    resolve(response);
                });
        });
                //////
        // this.onSaleLocationsChanged.next(this.saleLocations);
    }

    /**
     * Delete selected saleLocations
     */
    deleteSelectedSaleLocations(): void
    {
        for ( const saleLocationId of this.selectedSaleLocations )
        {
            // const saleLocation = this.saleLocations.find(_saleLocation => {
            //     return _saleLocation.id === saleLocationId;
            // });
            // const saleLocationIndex = this.saleLocations.indexOf(saleLocation);
            // this.saleLocations.splice(saleLocationIndex, 1);

            this.deleteSaleLocation(saleLocationId);
        }
        // this.onSaleLocationsChanged.next(this.saleLocations);
        this.deselectSaleLocations();
    }

}





@Injectable()
export class SaleLocationsDropdownService implements Resolve<any>
{
    onSaleLocationsDropdownChanged: BehaviorSubject<any>;

    saleLocationsDropdown: Dropdown[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onSaleLocationsDropdownChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getSaleLocationsDropdown(),
            ]).then(
                ([files]) => {

                    // TODO: try to check if Server sent Event cabin-class-updated socket
                    // this.onSearchTextChanged.subscribe(searchText => {
                    //     this.searchText = searchText;
                    //     this.getSaleLocationsDropdown();
                    // });


                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get saleLocations
     *
     * @returns {Promise<any>}
     */
    getSaleLocationsDropdown(): Promise<any>
    {
        return new Promise((resolve, reject) => {

                this._httpClient.get( environment.serverURL+'/api/sales_locations/dropdown')
                    .subscribe((response: any) => {

                        this.saleLocationsDropdown = response["hydra:member"];

                        this.saleLocationsDropdown = this.saleLocationsDropdown.map((saleLocation:any) => {
                            return new Dropdown(saleLocation["@id"],saleLocation.name,saleLocation);
                        });

                        this.onSaleLocationsDropdownChanged.next(this.saleLocationsDropdown);
                        resolve(this.saleLocationsDropdown);
                    }, reject);
            }
        );
    }
}

