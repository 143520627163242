import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { AgentType } from '../agent-type/agent-type.model';
import { Dropdown } from 'app/services/dropdown.model';

@Injectable()
export class AgentTypesService implements Resolve<any>
{
    agentTypes: any[];
    onAgentTypesChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;

    searchText: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private agentTypesDropdown: AgentTypesDropdownService,
    ) {
        // Set the defaults
        this.onAgentTypesChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAgentTypes()
            ]).then(
                () => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getAgentTypes('?simplesearch='+searchText);
                    });
                    resolve(null);
                },
                reject
            );
        });
    }

    /**
     * Get AgentTypes
     *
     * @returns {Promise<any>}
     */
    getAgentTypes(search=''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.serverURL + '/api/agent_types'+search)
                .subscribe((response: any) => {
                    // console.log(response);
                    // console.log(response['hydra:member']);
                    let agentTypes = response['hydra:member'];
                    this.agentTypes = agentTypes.map(agentType => {
                        return new AgentType(agentType);
                    });
                    this.onAgentTypesChanged.next(this.agentTypes);
                    resolve(this.agentTypes);
                }, reject);
        });
    }
    /**
    * Delete aircraft
    *
    * @param aircraftType
    * @returns {Promise<any>}
    */
    deleteAgentType(agentType): Promise<any> {
        // const agentTypeIndex = this.agentTypes.indexOf(agentType);
        // this.agentTypes.splice(agentTypeIndex, 1);

        let agentTypeId = agentType;
        if (typeof agentType === 'object' && agentType.id) {
            agentTypeId = agentType.id;
        }
        //////
        return new Promise((resolve, reject) => {
            // console.log(agentType);

            this._httpClient.delete(environment.serverURL + '/api/agent_types/' + agentTypeId)
                .subscribe(response => {
                    this.getAgentTypes();
                    this.agentTypesDropdown.getAgentTypesDropdown();
                    // this.onAgentsChanged.next(this.agents);
                    resolve(response);
                },reject);
        });
        //////
        // this.onAgentsChanged.next(this.agents);
    }
}


@Injectable()
export class AgentTypesDropdownService implements Resolve<any>
{
    onAgentTypesDropdownChanged: BehaviorSubject<any>;

    agentTypesDropdown: Dropdown[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onAgentTypesDropdownChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAgentTypesDropdown(),
            ]).then(
                ([files]) => {

                    // TODO: try to check if Server sent Event cabin-class-updated socket
                    // this.onSearchTextChanged.subscribe(searchText => {
                    //     this.searchText = searchText;
                    //     this.getAgentTypesDropdown();
                    // });


                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get agentTypes
     *
     * @returns {Promise<any>}
     */
    getAgentTypesDropdown(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient.get(environment.serverURL + '/api/agent_types/dropdown')
                .subscribe((response: any) => {

                    const agentTypesDropdown = response["hydra:member"];

                    this.agentTypesDropdown = agentTypesDropdown.map((agentType: any) => {
                        return new Dropdown(agentType["@id"], agentType.name, agentType);
                    });

                    this.onAgentTypesDropdownChanged.next(this.agentTypesDropdown);
                    resolve(this.agentTypesDropdown);
                }, reject);
        }
        );
    }
}

