import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from '@angular/material/tooltip';
import { BTDashboardComponent } from "./bt-dashboard.component";
import { AuthGuard } from 'app/auth/auth.guard';
import { RouterModule } from '@angular/router';

const routes = [
  {
    path: 'bt/dashboard',
    component: BTDashboardComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    BTDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    BTDashboardComponent
  ]
})
export class BTDashboardModule { }
