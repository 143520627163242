import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatRippleModule, MatSelectModule, MatSlideToggleModule, MatTableModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { EmptyRecordComponent } from './empty-record/empty-record.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatIconModule } from '@angular/material/icon';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { UsersUserFormDialogComponent } from '../security/users/user-form/user-form.component';
import { UsersService } from '../security/users/users.service';
import { RolesDropdownService } from '../security/roles/roles.service';
import { AgentTypesDropdownService } from '../system-configurations/agent-types/agent-types.service';


@NgModule({
    declarations:[
        PaginationComponent,
        EmptyRecordComponent,
        JsonViewerComponent,
        UsersUserFormDialogComponent,
    ],
    imports  : [
        MatButtonModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatChipsModule,
        MatSelectModule,
        MatTooltipModule,
        MatSlideToggleModule,

        FuseSharedModule,
        MatIconModule,
    ],
    exports  : [
        PaginationComponent,
        EmptyRecordComponent,
        JsonViewerComponent,
        UsersUserFormDialogComponent,
    ],
    providers: [
        UsersService,
        RolesDropdownService,
        AgentTypesDropdownService,
    ],
    entryComponents: [
        UsersUserFormDialogComponent,
    ]
})
export class AppSharedModule
{
}
