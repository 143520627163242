import { Component, OnInit, ViewChild } from '@angular/core';
import { CabinClassesDropdownService } from 'app/main/op/cabin-classes/cabin-classes.service';
import { SaleLocationsDropdownService } from 'app/main/op/sales-locations/sale-locations.service';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { capitalize } from 'lodash';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { Aircraft, HqAircraftReportResp } from '../models/hqreports';
import { CounterFinancialReportResp, Sale } from '../models/counterreports';
import { ReportsService } from '../services/reports.service';
import { ExportService } from './../../../services/export.service';
import { ClientsDropdownService } from 'app/main/security/clients/clients.service';
import { GtoolsService } from 'app/services/gtools.service';


@Component({
  selector: 'hq-sale-report',
  templateUrl: './hq-sale-report.component.html',
  styleUrls: ['../reports.theme.scss', './hq-sale-report.component.scss']
})
export class SalesReportComponent implements OnInit {
    financialReport: CounterFinancialReportResp;
    tableData: Sale[];
    isLoading: boolean = false;

  constructor(
    private reportsService: ReportsService,
    private exportService: ExportService,
    private cabinClassesDropdownService: CabinClassesDropdownService,
    private saleLocationsDropdownService: SaleLocationsDropdownService,
    private clientsDropdownService: ClientsDropdownService,
    private gTools: GtoolsService,
    // private flightNumbersDropdownService: FlightNumbersDropdownService
  ) {
    this.saleLocationsDropdownService.onSaleLocationsDropdownChanged.subscribe((saleLocationsDropdown) => {
        // this.stations = saleLocationsDropdown;
        this.stations = saleLocationsDropdown.map(saleLocationDropdown => {
            this.indexedStations[saleLocationDropdown.extra.id] = saleLocationDropdown;
            return {label: saleLocationDropdown.label, value: saleLocationDropdown.extra.id };
        });
      });
    this.cabinClassesDropdownService.onCabinClassesDropdownChanged.subscribe((cabinClassesDropdown) => {
        // this.cabinClasses = cabinClassesDropdown;
        this.cabinClasses = cabinClassesDropdown.map(cabinClassDropdown => {
            return {label: cabinClassDropdown.label, value: cabinClassDropdown.extra.id };
        });
      }); 
    this.clientsDropdownService.onClientsDropdownChanged.subscribe((clientsDropdown) => {
        this.clients = clientsDropdown.map(clientDropdown => {
            return {label: clientDropdown.label, value: clientDropdown.extra.id };
        });
    }); 
    this.reportsService.GetFlightNumbers().subscribe((result) => {
        if(capitalize(result.message) == 'Success'){
            this.flightNumbers = result.flightNumbers;
        }
    });
    this.reportsService.reportType = this.selectedFilter;
    this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
    this.reportsService.dateTo = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.getCounterFinancialReport();
  }

  getCounterFinancialReport() {
    // console.log('Counter Financial Report Service Works');
    
    this.reportsService.GetCounterFinancialReport()
        .subscribe((data: CounterFinancialReportResp) =>{
          this.financialReport = data;
          this.tableData = this.financialReport.sales;
          // console.log('fin report',this.tableData);
          
        });
  }

  filters = ['AllBooked', 'Booked', 'Ticketed', 'CheckedIn', 'Boarded', 'Lifted', 'NoShow', 'Cancelled', 'Expired', 'ModifiedTickets']; // 'ChangeFeePaid', 'PaxContactDetails', 'PNRWith10Pax', 'PNRByFlight', 'BookingByClient'
  selectedFilter = 'AllBooked';
  onChangeFilter(newFilter) {
      this.selectedFilter = newFilter;
      if (newFilter == 'AllBooked') {
          this.reportsService.reportType = null;
      }else{
          this.reportsService.reportType = newFilter;
      }
      this.getCounterFinancialReport();
  }
  dateRanges = ['Today', 'Last 7 Days', 'Last 14 Days', 'Last 30 Days', 'Custom'];
  selectedDateRange = "Today";
  onChangeDateRange(newDateRange) {
      this.selectedDateRange = newDateRange;
      if (newDateRange == 'Today') {
          this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 7 Days') {
          this.reportsService.dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 14 Days') {
          this.reportsService.dateFrom = moment().subtract(14, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 30 Days') {
          this.reportsService.dateFrom = moment().subtract(30, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Custom') {
          return;
      }
      this.getCounterFinancialReport();
  }
  flightNumbers = [{'airline': 'VM', 'flight_number': '2001'}];
  selectedFlightNumber = 'All Flights';
  onChangeFlightNumber(newFlightNumber) {
      this.selectedFlightNumber = newFlightNumber;
      if (newFlightNumber == 'All Flights') {
          this.reportsService.flightNumber = null;
      }else{
          this.reportsService.flightNumber = newFlightNumber;
      }
      this.getCounterFinancialReport();
  }
  stations = [];
  indexedStations = {};
  selectedStation = 'All Stations';
  onChangeStation(newStation) {
      this.selectedStation = newStation;
      if (newStation == 'All Stations') {
          this.reportsService.salesLocation = null;
      }else{
          this.reportsService.salesLocation = newStation;
      }
      this.getCounterFinancialReport();
  }

  users = [{label: 'Primal Admin', value: '1'}];
  selectedUser = 'All Users';
  onChangeUser(newUser) {
      this.selectedUser = newUser;
      if (newUser == 'All Users') {
          this.reportsService.user = null;
      }else{
          this.reportsService.user = newUser;
      }
      this.getCounterFinancialReport();
  }
  cabinClasses = [{label: 'Economy', value: 'Economy'}, {label: 'Business', value: 'Business'}];
  selectedCabinClass = 'All Cabin Classes';
  onChangeCabinClass(newCabinClass) {
      this.selectedCabinClass = newCabinClass;
      if (newCabinClass == 'All Cabin Classes') {
          this.reportsService.cabinClass = null;
      }else{
          this.reportsService.cabinClass = newCabinClass;
      }
      this.getCounterFinancialReport();
  }
  clients = [{label: 'Web', value: 'web'}, {label: 'Admin', value: 'admin'}];
  selectedClient = 'All Sale Channels';
  onChangeClient(newClient) {
      this.selectedCabinClass = newClient;
      if (newClient == 'All Sale Channels') {
          this.reportsService.client = null;
      }else{
          this.reportsService.client = newClient;
      }
      this.getCounterFinancialReport();
  }
  periods = ['Daily', 'Monthly', 'Yearly'];
  selectedPeriod = 'Daily';
  onChangePeriod(newPeriod) {
      this.selectedCabinClass = newPeriod;
      this.reportsService.periodSeries = newPeriod;
      this.getCounterFinancialReport();
  }
  downloadOptions = [{label: 'Download Excel Sheet', value: 'xls'}, 
                    // {label: 'Download CSV Sheet', value: 'csv'}, {label: 'Download PDF Sheet', value: 'pdf'}
                    ];
  selectedDownloadOption = 'share';
  onChangeDownloadOption(newDownloadOption) {
      this.selectedStation = newDownloadOption;
      if (newDownloadOption == 'xls') {
        this.gTools.exportToExcel('Sale Reports', 'xlsx');
      }else{
        
      }
  }
}