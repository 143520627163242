import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'bt-dashboard',
  templateUrl: './bt-dashboard.component.html',
  styleUrls: ['../../dashboard/dashboard.component.scss']
})
export class BTDashboardComponent implements OnInit {


  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    
    iconRegistry.addSvgIcon(
      'bt_reports',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bt_reports.svg'));
      iconRegistry.addSvgIcon(
        'flight',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/flight.svg'));
        iconRegistry.addSvgIcon(
          'agent',
          sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/agent.svg'));
    iconRegistry.addSvgIcon(
      'manual_ticket',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/manual_ticket.svg'));
      iconRegistry.addSvgIcon(
        'currency',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/currency.svg'));
        iconRegistry.addSvgIcon(
          'bus',
          sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bus.svg'));
          
          iconRegistry.addSvgIcon(
            'seatMap',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/seatMap.svg'));
            iconRegistry.addSvgIcon(
              'certified',
              sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/certified.svg'));
            
    iconRegistry.addSvgIcon(
        'bt_booking',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bt_booking.svg'));
    iconRegistry.addSvgIcon(
        'bt_sales',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bt_sales.svg'));
    iconRegistry.addSvgIcon(
        'bt_flight',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bt_flight.svg'));
    iconRegistry.addSvgIcon(
        'bt_overbooking',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bt_overbooking.svg'));
    iconRegistry.addSvgIcon(
        'bt_waitlist',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bt_waitlist.svg'));
  }


  ngOnInit() {
  }

}
