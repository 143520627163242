import { Component, OnInit } from '@angular/core';
import { GtoolsService } from 'app/services/gtools.service';
import * as moment from 'moment';
import { HqPassengerReportResp, Station } from '../models/hqreports';
import { ReportsService } from '../services/reports.service';
import { ExportService } from './../../../services/export.service';

@Component({
  selector: 'app-hq-passenger-report',
  templateUrl: './hq-passenger-report.component.html',
  styleUrls: ['../reports.theme.scss', './hq-passenger-report.component.scss']
})
export class HqPassengerReportComponent implements OnInit {
  passengersReport?: HqPassengerReportResp;
  stations?: Station[];
  // view: any[];
  width: number = 700;
  height: number = 300;
  fitContainer: boolean = false;

  view: any[] = [1000, 400];
  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Airport';
  showYAxisLabel = true;
  yAxisLabel = 'Passengers';
  timeline = true;
  doughnut = true;
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };

  salesPieColorScheme = {
    domain: ['#5EDFC1', '#D84A59', '#CFBC10']
  };
  genderPieColorScheme = {
    domain: ['#5EDFC1', '#D84A59']
  };
  //pie
  showLabels = true;
  // data goes here
  public multi = [
    {
      "name": "ABV",
      "series": [
        {
          "name": "2018",
          "value": 2243772
        },
        {
          "name": "2017",
          "value": 1227770
        },
        {
          "name": "2016",
          "value": 1227770
        }
      ]
    },
    {
      "name": "LOS",
      "series": [
        {
          "name": "2018",
          "value": 1126000
        },
        {
          "name": "2017",
          "value": 764666
        },
        {
          "name": "2016",
          "value": 1227770
        }
      ]
    },
    {
      "name": "YOL",
      "series": [
        {
          "name": "2018",
          "value": 296215
        },
        {
          "name": "2017",
          "value": 209122
        },
        {
          "name": "2016",
          "value": 1227770
        }
      ]
    },
    {
      "name": "JOS",
      "series": [
        {
          "name": "2018",
          "value": 257363
        },
        {
          "name": "2017",
          "value": 205350
        },
        {
          "name": "2016",
          "value": 1227770
        }
      ]
    },
    {
      "name": "SKO",
      "series": [
        {
          "name": "2018",
          "value": 196750
        },
        {
          "name": "2017",
          "value": 129246
        },
        {
          "name": "2016",
          "value": 1227770
        }
      ]
    },
    {
      "name": "BNI",
      "series": [
        {
          "name": "2018",
          "value": 204617
        },
        {
          "name": "2017",
          "value": 149797
        },
        {
          "name": "2016",
          "value": 1227770
        }
      ]
    }
  ];

public salesPie = [
  {
    "name": "Revenue",
    "value": 2243772
  },
  {
    "name": "Profit",
    "value": 1126000
  },
  {
    "name": "Expenses",
    "value": 1116000
  }
]; 
public genderPie = [
  {
    "name": "Male",
    "value": 100
  },
  {
    "name": "Female",
    "value": 32
  }
]; 


constructor(
  private reportsService: ReportsService,
  private gTools: GtoolsService,
  ) { }

ngOnInit() {
  this.getHqPassengerReport();
  this.getCounterReportData();
}

getHqPassengerReport() {
  // console.log('HQ Passenger Report Service Works');

  this.reportsService.GetHqPassengerReport()
      .subscribe((data: HqPassengerReportResp) =>{
        this.passengersReport = data;
        this.stations = this.passengersReport.stations;
        // console.log('hq passenger',this.passengersReport);
        this.passengersReport.total_ticketed_pax_count = 132;
      });
}


getCounterReportData(){
  this.reportsService.GetCounterPassengerReport().subscribe((data)=> {
    // console.log(data);
  })
}
genNameAbbreviation(name:string) {
  let subName = name.split(" ");
  const nameAbbreviation = subName[0][0]+subName[1][0];
  return nameAbbreviation
}

  linearGuageView: any[] = [200, 100];
  // linearGuageColorScheme = {
  //   domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  // };
  // value: number = 50;
  // previousValue: number = 70;
  // units: string = 'counts';

  onSelect(event) {
    // console.log(event);
  }

  
  filters = ['AllBooked', 'Booked', 'Ticketed', 'CheckedIn', 'Boarded', 'Lifted', 'NoShow', 'Cancelled', 'Expired', 'ModifiedTickets']; // 'ChangeFeePaid', 'PaxContactDetails', 'PNRWith10Pax', 'PNRByFlight', 'BookingByClient'
  selectedFilter = 'AllBooked';
  onChangeFilter(newFilter) {
      this.selectedFilter = newFilter;
      if (newFilter == 'AllBooked') {
          this.reportsService.reportType = null;
      }else{
          this.reportsService.reportType = newFilter;
      }
      this.getHqPassengerReport();
  }
  dateRanges = ['Today', 'Last 7 Days', 'Last 14 Days', 'Last 30 Days', 'Custom'];
  selectedDateRange = "Today";
  onChangeDateRange(newDateRange) {
      this.selectedDateRange = newDateRange;
      if (newDateRange == 'Today') {
          this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 7 Days') {
          this.reportsService.dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 14 Days') {
          this.reportsService.dateFrom = moment().subtract(14, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 30 Days') {
          this.reportsService.dateFrom = moment().subtract(30, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Custom') {
          return;
      }
      this.getHqPassengerReport();
  }
  flightNumbers = [{'airline': 'VM', 'flight_number': '2001'}];
  selectedFlightNumber = 'All Flights';
  onChangeFlightNumber(newFlightNumber) {
      this.selectedFlightNumber = newFlightNumber;
      if (newFlightNumber == 'All Flights') {
          this.reportsService.flightNumber = null;
      }else{
          this.reportsService.flightNumber = newFlightNumber;
      }
      this.getHqPassengerReport();
  }
  stationsd = [{label: 'Abuja Station', value: '1'}];
  selectedStation = 'All Stations';
  onChangeStation(newStation) {
      this.selectedStation = newStation;
      if (newStation == 'All Stations') {
          this.reportsService.salesLocation = null;
      }else{
          this.reportsService.salesLocation = newStation;
      }
      this.getHqPassengerReport();
  }
  cabinClasses = [{label: 'Economy', value: 'Economy'}, {label: 'Business', value: 'Business'}];
  selectedCabinClass = 'All Cabin Classes';
  onChangeCabinClass(newCabinClass) {
      this.selectedCabinClass = newCabinClass;
      if (newCabinClass == 'All Cabin Classes') {
          this.reportsService.cabinClass = null;
      }else{
          this.reportsService.cabinClass = newCabinClass;
      }
      this.getHqPassengerReport();
  }
  clients = [{label: 'Web', value: 'web'}, {label: 'Admin', value: 'admin'}];
  selectedClient = 'All Sale Channels';
  onChangeClient(newClient) {
      this.selectedCabinClass = newClient;
      if (newClient == 'All Sale Channels') {
          this.reportsService.client = null;
      }else{
          this.reportsService.client = newClient;
      }
      this.getHqPassengerReport();
  }
  periods = ['Daily', 'Monthly', 'Yearly'];
  selectedPeriod = 'Daily';
  onChangePeriod(newPeriod) {
      this.selectedCabinClass = newPeriod;
      this.reportsService.periodSeries = newPeriod;
      this.getHqPassengerReport();
  }
  downloadOptions = [{label: 'Download Excel Sheet', value: 'xls'}, 
                    // {label: 'Download CSV Sheet', value: 'csv'}, {label: 'Download PDF Sheet', value: 'pdf'}
                    ];
  selectedDownloadOption = 'share';
  onChangeDownloadOption(newDownloadOption) {
      this.selectedStation = newDownloadOption;
      if (newDownloadOption == 'xls') {
        this.gTools.exportToExcel('Sale Reports', 'xlsx');
      }else{
        
      }
  }
}
