import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CabinClassesDropdownService } from 'app/main/op/cabin-classes/cabin-classes.service';
import { SaleLocationsDropdownService } from 'app/main/op/sales-locations/sale-locations.service';
import { ClientsDropdownService } from 'app/main/security/clients/clients.service';
import { GtoolsService } from 'app/services/gtools.service';
import { capitalize } from 'lodash';
import * as moment from 'moment';
// import { PdfGeneratorService } from 'app/services/pdf-generator.service'; 
import { Aircraft, HqAircraftReportResp } from '../models/hqreports';
import { ReportsService } from '../services/reports.service';
import { ExportService } from './../../../services/export.service';
// import { NgxPrintService } from 'ngx-print';


const CSV_EXTENSION = '.csv';
@Component({
  selector: 'hq-booking-report',
  templateUrl: './hq-booking-report.component.html',
  styleUrls: ['../reports.theme.scss', './hq-booking-report.component.scss']
})
export class BookingsReportComponent implements OnInit {

  reportsArray: any = [];
  totalPaxCount: any = 0;
  totalTrips : number = 0;
  totalSales: number = 0;
//   @ViewChild('printArea', { static: false }) printArea: ElementRef;
  @ViewChild('printArea', { static: false }) printArea!: ElementRef;

  constructor(
    private reportsService: ReportsService,
    private cabinClassesDropdownService: CabinClassesDropdownService,
    private saleLocationsDropdownService: SaleLocationsDropdownService,
    private exportService: ExportService,
    private clientsDropdownService: ClientsDropdownService,
    private gTools: GtoolsService,
    // private ngxPrintService: NgxPrintService
    // private pdfGenService : PdfGeneratorService
    // private flightNumbersDropdownService: FlightNumbersDropdownService
  ) {
    this.saleLocationsDropdownService.onSaleLocationsDropdownChanged.subscribe((saleLocationsDropdown) => {
        // this.stations = saleLocationsDropdown;
        this.stations = saleLocationsDropdown.map(saleLocationDropdown => {
            return {label: saleLocationDropdown.label, value: saleLocationDropdown.extra.id };
        });
      });
    this.cabinClassesDropdownService.onCabinClassesDropdownChanged.subscribe((cabinClassesDropdown) => {
        // this.cabinClasses = cabinClassesDropdown;
        this.cabinClasses = cabinClassesDropdown.map(cabinClassDropdown => {
            return {label: cabinClassDropdown.label, value: cabinClassDropdown.extra.id };
        });
      }); 
    this.clientsDropdownService.onClientsDropdownChanged.subscribe((clientsDropdown) => {
        this.clients = clientsDropdown.map(clientDropdown => {
            return {label: clientDropdown.label, value: clientDropdown.extra.id };
        });
    }); 
    this.reportsService.GetFlightNumbers().subscribe((result) => {
        if(capitalize(result.message) == 'Success'){
            this.flightNumbers = result.flightNumbers;
        }
    });
    this.reportsService.reportType = this.selectedFilter;
    this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
    this.reportsService.dateTo = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.getBookingsReport();
  }


  genBookingReport(){
    this.reportsService.GetBookingsReport().subscribe((data: any) => {
    //   this.reportsService.GetBookingsReport(type).subscribe((data)=> {
          // console.log(data);


      })
  }
  sumReducer(sum, val) {
    return Number(sum) + Number(val);
  }





  dateRanges = ['Today', 'Last 7 Days', 'Last 14 Days', 'Last 30 Days', 'Custom'];
  selectedDateRange = "Today";
  onChangeDateRange(newDateRange) {
      this.selectedDateRange = newDateRange;
      if (newDateRange == 'Today') {
          this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 7 Days') {
          this.reportsService.dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 14 Days') {
          this.reportsService.dateFrom = moment().subtract(14, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Last 30 Days') {
          this.reportsService.dateFrom = moment().subtract(30, 'days').format('YYYY-MM-DD');
          this.reportsService.dateTo = moment().format('YYYY-MM-DD');
      }
      if (newDateRange == 'Custom') {
          return;
      }
      this.getBookingsReport();
  }
  flightNumbers = [{'airline': 'VM', 'flight_number': '2001'}];
  selectedFlightNumber = 'All Flights';
  onChangeFlightNumber(newFlightNumber) {
      this.selectedFlightNumber = newFlightNumber;
      if (newFlightNumber == 'All Flights') {
          this.reportsService.flightNumber = null;
      }else{
          this.reportsService.flightNumber = newFlightNumber;
      }
      this.getBookingsReport();
  }
  filters = ['AllBooked', 'Booked', 'Ticketed', 'CheckedIn', 'Boarded', 'Lifted', 'NoShow', 'Cancelled', 'Expired', 'ModifiedTickets', 'All PNR', 'PNR with 10 or more pax']; // 'ChangeFeePaid', 'PaxContactDetails', 'PNRWith10Pax', 'PNRByFlight', 'BookingByClient'
  selectedFilter = 'AllBooked';
  onChangeFilter(newFilter) {
      this.selectedFilter = newFilter;
      if (newFilter == 'AllBooked') {
          this.reportsService.reportType = null;
      }else{
          this.reportsService.reportType = newFilter;
      }
      this.getBookingsReport();
  }
  stations = [{label: 'Abuja Station', value: '1'}];
  selectedStation = 'All Stations';
  onChangeStation(newStation) {
      this.selectedStation = newStation;
      if (newStation == 'All Stations') {
          this.reportsService.salesLocation = null;
      }else{
          this.reportsService.salesLocation = newStation;
      }
      this.getBookingsReport();
  }
  cabinClasses = [{label: 'Economy', value: 'Economy'}, {label: 'Business', value: 'Business'}];
  selectedCabinClass = 'All Cabin Classes';
  onChangeCabinClass(newCabinClass) {
      this.selectedCabinClass = newCabinClass;
      if (newCabinClass == 'All Cabin Classes') {
          this.reportsService.cabinClass = null;
      }else{
          this.reportsService.cabinClass = newCabinClass;
      }
      this.getBookingsReport();
  }
  clients = [{label: 'Web', value: 'web'}, {label: 'Admin', value: 'admin'}];
  selectedClient = 'All Sale Channels';
  onChangeClient(newClient) {
      this.selectedCabinClass = newClient;
      if (newClient == 'All Sale Channels') {
          this.reportsService.client = null;
          this.gTools.exportToExcel('ReportTable', 'xlsx');
      }else{
          this.reportsService.client = newClient;
      }
      this.getBookingsReport();
  }
  periods = ['Daily', 'Monthly', 'Yearly'];
  selectedPeriod = 'Daily';
  onChangePeriod(newPeriod) {
      this.selectedCabinClass = newPeriod;
      this.reportsService.periodSeries = newPeriod;
      this.getBookingsReport();
  }
  downloadOptions = [
                    {label: 'Download Excel Sheet', value: 'xls'}, 
                    {label: 'Download CSV Sheet', value: 'csv'}, 
                    {label: 'Download PDF Sheet', value: 'pdf'}
                    ];
  selectedDownloadOption = 'share';
  onChangeDownloadOption(newDownloadOption) {
      this.selectedStation = newDownloadOption;
      if (newDownloadOption == 'xls') {
        this.gTools.exportToExcel('Booking Reports', 'xlsx');
      }else if(newDownloadOption == 'csv'){
            this.exportService.exportToCsv(
                this.bookingsReportSummary, 
                'Hq Booking Report', 
                ['sn', 'flightNumber', 'origin', 'destination', 'date', 'recordLocator','ticketNumber', 'title', 'firstName', 'lastName', 'status' ]);
      }
    //   else if(newDownloadOption == 'pdf'){
    //     newDownloadOption.setAttribute('printSectionId="printArea"', '[useExistingCss]="true"', 'ngxPrint');
    //   }
  }
  
  isLoading;
  bookingsReportSummary;
  getBookingsReport(){
    this.isLoading = true;
    this.reportsService.GetBookingsReport().subscribe((data)=> {
      this.isLoading = false;
      this.bookingsReportSummary = data.bookings;
      this.bookingsReportSummary = this.bookingsReportSummary.map((data)=>{
          data.date = moment(data.date.date).format('YYYY-MM-DD');
          return data;
      })
    })
  }

  compareFn(a: any, b: any): boolean {
    return a === b;
  }
}