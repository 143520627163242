// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    PRIMAL_CLIENT_ID: '3_1e1i3yq4qmpwcwoo04wo0sgso4g4c8gsswc00cgs44ws4k0k84',
    PRIMAL_CLIENT_SECRET: '683xm1s63csgccoc0kowsscos4o4s40wgk40wskw4w884wc4ww',
    reportServerURL: 'http://localhost:8080/jasperserver',
    // serverURL: 'https://ec2-13-40-194-58.eu-west-2.compute.amazonaws.com:8000',
    serverURL: 'http://parsapi.us-east-1.elasticbeanstalk.com',
    // serverURL: 'http://localhost:8000',
    authURI: '/oauth/v2/token',
    STORAGE_SECRET: 'SEC1234567890',
    defaultAvatar: '/assets/images/avatars/profile.jpg',
    version: 'V0.10',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// http://192.168.10.100:8000/api/boardingpass?paxId=$P{paxId}&bookingLegId=$P{bookingLegId}
