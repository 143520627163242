import { NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";
import { DashboardModule} from "./main/dashboard/dashboard.module";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { BTDashboardModule } from './main/bat/bt-dashboard/bt-dashboard.module';
import { FakeDbService } from "./fake-db/fake-db.service";
import { InMemoryWebApiModule } from "angular-in-memory-web-api";

import { TokenInterceptor } from './auth/token-interceptor';
import { AuthGuard } from './auth/auth.guard';
import { GtoolsService } from './services/gtools.service';
import { LoaderInterceptor } from './services/loader-interceptor';
import { SnackbarInterceptor } from './services/snackbar-interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ObjectLengthPipe } from './object-length.pipe';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CdkColumnDef } from '@angular/cdk/table';
import { AclGuard } from './auth/acl.guard';
import { ProjectDashboardModule } from './main/dashboards/project/project.module';
import { AnalyticsDashboardModule } from './main/dashboards/analytics/analytics.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { ReportsModule } from './main/reports/reports.module';
import { AppSharedModule } from './main/shared/shared.module';

const appRoutes: Routes = [
    // {
    //     path: "pages",
    //     loadChildren: "./main/pages/pages.module#PagesModule",
    // },
    // {
    //     path: "ui",
    //     loadChildren: "./main/ui/ui.module#UIModule",
    // },
    {
        path: "pages",
        //loadChildren: "./main/pages/pages.module#PagesModule",
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },
    {
        path: "ui",
        //loadChildren: "./main/ui/ui.module#UIModule",
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    },
    {
        path: "error-404",
        redirectTo: "/pages/errors/error-404",
    },
    {
        path: "security",
        //loadChildren: "./main/security/security.module#SecurityModule",
        loadChildren: () => import('./main/security/security.module').then(m => m.SecurityModule),
        canActivate: [AuthGuard,AclGuard]
    },
    {
        path: "config",
        //loadChildren: "./main/system-configurations/system-configurations.module#SystemConfigurationsModule",
        loadChildren: () => import('./main/system-configurations/system-configurations.module').then(m => m.SystemConfigurationsModule),
        canActivate: [AuthGuard,AclGuard],
    },
    {
        path: "op",
        //loadChildren: "./main/op/op.module#OPModule",
        loadChildren: () => import('./main/op/op.module').then(m => m.OPModule),
        canActivate: [AuthGuard,AclGuard],
    },
    {
        path        : 'dcs',
        //loadChildren: './main/dcs/dcs.module#DCSModule',
        loadChildren: () => import('./main/dcs/dcs.module').then(m => m.DCSModule),
        canActivate : [AuthGuard,AclGuard]
    },
    // {
    //     path: "bt",
    //     loadChildren: "./main/bt/bts.module#BTModule",
    //     canActivate: [AuthGuard,AclGuard],
    // },
    {
        path: "bat",
        //loadChildren: "./main/bat/bat.module#BatModule",
        loadChildren: () => import('./main/bat/bat.module').then(m => m.BatModule),
        canActivate: [AuthGuard,AclGuard],
    },
    {
        path: "dashboard", 
        //loadChildren: "./main/dashboard/dashboard.module#DashboardModule",
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: "dashboards/analytics", 
    //     loadChildren: "./main/dashboards/analytics/analytics.module#AnalyticsDashboardModule",
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: "dashboards/project", 
    //     loadChildren: "./main/dashboards/project/project.module#ProjectDashboardModule",
    //     canActivate: [AuthGuard]
    // },
    {
        path: "bt/dashboard", 
        //loadChildren: "./main/bat/bt-dashboard/bt-dashboard.module#BTDashboardModule",
        loadChildren: () => import('./main/bat/bt-dashboard/bt-dashboard.module').then(m => m.BTDashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'messaging',
        loadChildren: () => import('./main/broadcast/broadcasts.module').then(m => m.BroadcastsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-activity',
        loadChildren: () => import('./main/my-activity/my-activity.module').then(m => m.ActivityModule),
        canActivate: [AuthGuard]
    },
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
        // canActivate: [AuthGuard], //no need for redirect
    },
    {
        path: "**",
        redirectTo: "/pages/errors/error-404",
        // canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        AppComponent,
        // ObjectLengthPipe
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(
            appRoutes
            ),

        TranslateModule.forRoot(),

        // remove to connect to real api
        // InMemoryWebApiModule.forRoot(FakeDbService, {
        //     delay             : 0,
        //     passThruUnknownUrl: true
        // }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        NgxMaterialTimepickerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        DashboardModule,
        AppSharedModule,
        // ProjectDashboardModule,
        // AnalyticsDashboardModule,
        ReportsModule,
        BTDashboardModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
        //AuthenticationService,      
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: SnackbarInterceptor,
        multi: true
      },
    //   {
    //     provide: NgxMatDateAdapter,
    //     useClass: CustomDateAdapter,
    //     deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    //   },
      {
        provide: MAT_DATE_LOCALE, 
        useValue: 'en-GB'
      },
      CdkColumnDef,
      GtoolsService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}


