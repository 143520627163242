import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'calendar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/calendar.svg'));
    iconRegistry.addSvgIcon(
      'calendarBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/calendarBlue.svg'));
    iconRegistry.addSvgIcon(
      'currency',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/currency.svg'));
    iconRegistry.addSvgIcon(
      'currencyBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/currencyBlue.svg'));
    iconRegistry.addSvgIcon(
      'fare',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/fare.svg'));
    iconRegistry.addSvgIcon(
      'fareBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/fareBlue.svg'));
    iconRegistry.addSvgIcon(
      'airliner',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/airliner.svg'));
    iconRegistry.addSvgIcon(
      'airlinerBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/airlinerBlue.svg'));
    iconRegistry.addSvgIcon(
      'city',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/city.svg'));
    iconRegistry.addSvgIcon(
      'cityBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/cityBlue.svg'));
    iconRegistry.addSvgIcon(
      'country',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/country.svg'));
    iconRegistry.addSvgIcon(
      'countryBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/countryBlue.svg'));
    iconRegistry.addSvgIcon(
      'international-departures',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/international-departures.svg'));
    iconRegistry.addSvgIcon(
      'international-departuresBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/international-departuresBlue.svg'));
    iconRegistry.addSvgIcon(
      'timezone',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/timezone.svg'));
    iconRegistry.addSvgIcon(
      'timezoneBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/timezoneBlue.svg'));
    iconRegistry.addSvgIcon(
      'bus',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/bus.svg'));
    iconRegistry.addSvgIcon(
      'busBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/busBlue.svg'));
    iconRegistry.addSvgIcon(
      'certified',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/certified.svg'));
    iconRegistry.addSvgIcon(
      'certifiedBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/certifiedBlue.svg'));
    iconRegistry.addSvgIcon(
      'language',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/language.svg'));
    iconRegistry.addSvgIcon(
      'languageBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/languageBlue.svg'));
    iconRegistry.addSvgIcon(
      'messageText',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/messageText.svg'));
    iconRegistry.addSvgIcon(
      'messageTextBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/messageTextBlue.svg'));
    iconRegistry.addSvgIcon(
      'operator',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/operator.svg'));
    iconRegistry.addSvgIcon(
      'operatorBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/operatorBlue.svg'));
    iconRegistry.addSvgIcon(
      'translate',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/translate.svg'));
    iconRegistry.addSvgIcon(
      'translateBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/translateBlue.svg'));
    iconRegistry.addSvgIcon(
      'agent',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/agent.svg'));
    iconRegistry.addSvgIcon(
      'seatMap',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/seatMap.svg'));
    iconRegistry.addSvgIcon(
      'cabin',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/cabin.svg'));
    iconRegistry.addSvgIcon(
      'cabinBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/cabinBlue.svg'));
    iconRegistry.addSvgIcon(
      'lopa',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/lopa.svg'));
    iconRegistry.addSvgIcon(
      'lopaBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/lopaBlue.svg'));
    iconRegistry.addSvgIcon(
      'taxBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/taxBlue.svg'));
    iconRegistry.addSvgIcon(
      'tax',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/tax.svg'));
    iconRegistry.addSvgIcon(
      'product',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/product.svg'));

    iconRegistry.addSvgIcon(
      'productBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/productBlue.svg'));

    iconRegistry.addSvgIcon(
      'categories',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/categories.svg'));
    iconRegistry.addSvgIcon(
      'categoriesBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/categoriesBlue.svg'));
    iconRegistry.addSvgIcon(
      'salesLocation',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/salesLocation.svg'));
    iconRegistry.addSvgIcon(
      'salesLocationBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/salesLocationBlue.svg'));
    iconRegistry.addSvgIcon(
      'flight',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/flight.svg'));
    iconRegistry.addSvgIcon(
      'flightBlue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customIcons/flightBlue.svg'));
  }


  ngOnInit() {
  }

}
