import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-record',
  templateUrl: './empty-record.component.html',
  styleUrls: ['./empty-record.component.scss']
})
export class EmptyRecordComponent {
   @Input() recordName: string = '';
  constructor() { }
}
