import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { User } from './user.model';
import { environment } from 'environments/environment';
import { Paginator } from 'app/main/shared/pagination/paginator.model';

@Injectable()
export class UsersService implements Resolve<any>
{
    onUsersChanged: BehaviorSubject<any>;
    onPaginatorChanged: BehaviorSubject<any>=new BehaviorSubject(null);
    onSelectedUsersChanged: BehaviorSubject<any>;
    // onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    users: User[];
    // user: any;
    selectedUsers: number[] = [];

    searchText: string;
    filterBy: number = -1;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onUsersChanged = new BehaviorSubject([]);
        this.onSelectedUsersChanged = new BehaviorSubject([]);
        // this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUsers(),
                // this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        let searchQuery = '?simplesearch='+searchText;
                        if(this.filterBy && this.filterBy!==-1) 
                            searchQuery += '&module_roles='+this.filterBy; 
                        this.getUsers(searchQuery);
                    });

                    this.onFilterChanged.subscribe(filter => {
                        // this.filterBy = filter;
                        // let searchQuery = '?module_roles='+filter;
                        // if(this.searchText) 
                        //     searchQuery += '&simplesearch='+this.searchText; 

                        // if(this.searchText)
                        // this.searchText = searchText;
                        let searchText = this.searchText?this.searchText:'';
                        let searchQuery = '?simplesearch='+searchText;

                        this.filterBy = filter;
                        if(this.filterBy && this.filterBy!==-1) 
                            searchQuery += '&module_roles='+this.filterBy; 
                        this.getUsers(searchQuery);
                    });

                    resolve(null);

                },
                reject
            );
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(id): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get(environment.serverURL+'/api/users/'+id)
                    .subscribe((response: any) => {

                        const user = new User(response);
                        // this.onUsersChanged.next(this.users);
                        resolve(user);
                    }, reject);
            }
        );
    }

    getUserProfile(id): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get(environment.serverURL+'/api/users/'+id+'/profile')
                    .subscribe((response: any) => {

                        const user = new User(response);
                        // this.onUsersChanged.next(this.users);
                        resolve(user);
                    }, reject);
            }
        );
    }

    /**
     * Get users
     *
     * @returns {Promise<any>}
     */
    getUsers(search=''): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get(environment.serverURL+'/api/users'+search)
                    .subscribe((response: any) => {

                        // this.users = response.data;
                        let users = response["hydra:member"];
                        this.onPaginatorChanged.next(new Paginator(response["hydra:view"], this, response["hydra:totalItems"],'getUsers',users.length));

                        // // sidebar filter
                        // if ( this.filterBy !== -1 )
                        // {
                        //     users = users.filter(_user => {
                        //         let modules = _user.module_roles;
                        //         debugger;
                        //         for (let i = 0; i < modules.length; i++) {
                        //             const mod = modules[i];
                        //             return '/api/roles/'+mod.id === this.filterBy;
                        //         }
                        //     });
                        // }

                        // // .. search
                        // if ( this.searchText && this.searchText !== '' )
                        // {
                        //     users = FuseUtils.filterArrayByString(users, this.searchText);
                        // }

                        this.users = users.map(user => {
                            return new User(user);
                        });

                        this.onUsersChanged.next(this.users);
                        resolve(this.users);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected user by id
     *
     * @param id
     */
    toggleSelectedUser(id): void
    {
        // First, check if we already have that user as selected...
        if ( this.selectedUsers.length > 0 )
        {
            const index = this.selectedUsers.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedUsers.splice(index, 1);

                // Trigger the next event
                this.onSelectedUsersChanged.next(this.selectedUsers);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedUsers.push(id);

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedUsers.length > 0 )
        {
            this.deselectUsers();
        }
        else
        {
            this.selectUsers();
        }
    }

    /**
     * Select users
     *
     * @param filterParameter
     * @param filterValue
     */
    selectUsers(filterParameter?, filterValue?): void
    {
        this.selectedUsers = [];

        // If there is no filter, select all users
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedUsers = [];
            this.users.map(user => {
                this.selectedUsers.push(user.id);
            });
        }

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);
    }

    /**
     * Create user
     *
     * @param user
     * @returns {Promise<any>}
     */
    createUser(user): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.post(environment.serverURL+'/api/users', {...user})
                .subscribe(response => {
                    this.getUsers();
                    let user = new User(response);
                    resolve(user);
                },reject);
        });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    updateUser(user): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.patch(environment.serverURL+'/api/users/' + user.id, {...user})
                .subscribe(response => {
                    this.getUsers();
                    let user = new User(response);
                    resolve(user);
                },reject);
        });
    }

    /**
     * Update user
     *
     * @param user
     * @returns {Promise<any>}
     */
    updateUserProfile(user): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.patch(environment.serverURL+'/api/users/'+user.id+'/profile', {...user})
                .subscribe(response => {
                    this.getUsers();
                    let user = new User(response)
                    resolve(user);
                },reject);
        });
    }

    /**
     * Deselect users
     */
    deselectUsers(): void
    {
        this.selectedUsers = [];

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);
    }

    /**
     * Delete user
     *
     * @param user
     * @returns {Promise<any>}
     */
    deleteUser(user): Promise<any>
    {
        // const userIndex = this.users.indexOf(user);
        // this.users.splice(userIndex, 1);

        let userId = user;
        if ( typeof user === 'object' && user.id){
            userId = user.id;
        }
        //////
        return new Promise((resolve, reject) => {
            // console.log(user);

            this._httpClient.delete(environment.serverURL+'/api/users/' + userId )
                .subscribe(response => {
                    this.getUsers();

                    // this.onUsersChanged.next(this.users);
                    resolve(response);
                },reject);
        });
                //////
        // this.onUsersChanged.next(this.users);
    }

    /**
     * Delete selected users
     */
    deleteSelectedUsers(): void
    {
        for ( const userId of this.selectedUsers )
        {
            // const user = this.users.find(_user => {
            //     return _user.id === userId;
            // });
            // const userIndex = this.users.indexOf(user);
            // this.users.splice(userIndex, 1);

            this.deleteUser(userId);
        }
        // this.onUsersChanged.next(this.users);
        this.deselectUsers();
    }

}
