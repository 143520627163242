import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CounterFinancialReportResp, CounterPassengerReportResp } from '../models/counterreports';
import { HqAircraftReportResp, HqDashboardReportResp, HqPassengerReportResp, HqSalesReportResp } from '../models/hqreports';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import * as html2pdf from 'html2pdf.js';

let html2pdf: any;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  baseUrl: string = "assets/fake-reports-db";

  constructor(private httpClient: HttpClient) {
    // GetHqPeriodicReport(); GetHqAircraftSalesReport(); GetHqFlightsReport(); GetHqRoutesReport();
   }

  // Counter Reports Service Methods
  GetCounterFinancialReport(): Observable<any>{
    // return this.httpClient.get<any>(`${this.baseUrl}/countersales.json`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/counter_sales_report`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/counter_sales_report${this.urlFilterParams()}`);
    // return this.httpClient.get<CounterFinancialReportResp>(`${this.baseUrl}/financialReportUrl`);
  }

  

  TotalSalesByFlightNumber(): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sales/flights`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sales/flights${this.urlFilterParams()}`);
  }
  TotalSalesByAircraft(): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sales/aircrafts`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sales/aircrafts${this.urlFilterParams()}`);
  }

  GetCounterPassengerReport(): Observable<any>{
    // return this.httpClient.get<any>(`${this.baseUrl}/counterpassengers.json`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/passenger_report`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/passenger_report${this.urlFilterParams()}`);
    // return this.httpClient.get<CounterPassengerReportResp>(`${this.baseUrl}/passengerReportUrl`);
  }


  genReportSummary(): Observable<any> {
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/summary`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/summary${this.urlFilterParams()}`);
  }
  // HQ Reports Service Methods
  GetHqDashboardReport(): Observable<any>{
    return this.httpClient.get<HqDashboardReportResp>(`${this.baseUrl}/hqDashboardReporttUrl`);
  }

  GetHqPassengerReport(): Observable<any>{
    return this.httpClient.get<HqPassengerReportResp>(`${this.baseUrl}/hq-passengers.json`);
  }

  GetHqAircraftReport(): Observable<any>{
    return this.httpClient.get<HqAircraftReportResp>(`${this.baseUrl}/hq-aircraft.json`);
  }

  GetHqSalesReport(): Observable<any>{
    return this.httpClient.get<HqSalesReportResp>(`${this.baseUrl}/hq-sales.json`);
  }

  // Fetching all flight numbers on database
  GetFlightNumbers(): Observable<any>{
    return this.httpClient.get<any>(`${environment.serverURL}/api/flights/flight_numbers`);
  }

  // Fetching all summay report: All Flights Daily, Monthly, Yearly Sales Report (GET request, possible parameters are dateFrom. dateTo, and periodSeries:"Daily"|"Monthly"|"Yearly")
  GetHqPeriodicReport(urlFilterParams?): Observable<any>{
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sales/periodic${this.urlFilterParams()}`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sales/periodic`, this.reportParams());
  }
  
  // Total Sales Made by Aircraft in Date Range (GET params: start_date, end_date)
  GetHqAircraftsReport(urlFilterParams?): Observable<any>{
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sales/aircrafts${this.urlFilterParams()}`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sales/aircrafts`, this.reportParams());
  }
  
  // Total Sales Made by Flight Number in Date Range (GET params: start_date, end_date, sales_location?)
  GetHqFlightsReport(urlFilterParams?): Observable<any>{
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sales/flights${this.urlFilterParams()}`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sales/flights`, this.reportParams());
  }

  // Total Sales Made by Routes in Date Range (GET params: start_date, end_date, flight_number?)
  GetHqRoutesReport(urlFilterParams?): Observable<any>{
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sales/routes${this.urlFilterParams()}`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sales/routes`, this.reportParams());
  }
  
  // Total Summary of Assets (Aircrafts, Sale Locations, Users), Operations (Flights: Scheduled | Active | Cancelled, Passengers: Booked | Ticketed | Flown), 
  // Account/Sales (Revenue: Booked | Ticketed | Flown)
  GetHqSummaryReport(urlFilterParams?): Observable<any>{
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/summary${this.urlFilterParams()}`);
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/summary`, this.reportParams());
  }

  // Bookings Report
  GetBookingsReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/booking`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/booking${this.urlFilterParams()}`);
  }

  // Flights Report
  GetFlightsReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/flight`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/flight${this.urlFilterParams()}`);
  }

  // Payments Report
  GetPaymentsReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/payment`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/payment${this.urlFilterParams()}`);
  }

  // Fares Report
  GetFaresReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/fare`, this.reportParams());
    // return this.httpClient.get<any>(`${environment.serverURL}/api/reports/fare${this.urlFilterParams()}`);
    return this.httpClient.get<any>(`${environment.serverURL}/api/fares${this.urlFilterParams()}`);
  }

  // Sales Report
  GetSalesReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/sale`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/sale${this.urlFilterParams()}`);
  }

  // Baggages Report
  GetBaggagesReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/baggage`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/baggage${this.urlFilterParams()}`);
  }

  // Stations Report
  GetStationsReport(urlFilterParams?): Observable<any>{
    // return this.httpClient.post<any>(`${environment.serverURL}/api/reports/station`, this.reportParams());
    return this.httpClient.get<any>(`${environment.serverURL}/api/reports/station${this.urlFilterParams()}`);
  }
  
  dateFrom: string = moment().subtract(6, 'months').format('YYYY-MM-DD'); 
  dateTo: string = moment().add(60, 'days').format('YYYY-MM-DD'); 
  // dateFrom: string = moment().format('YYYY-MM-DD'); 
  // dateTo: string = moment().format('YYYY-MM-DD'); 
  flightNumber: string; 
  salesLocation: string;
  user: string;
  aircraft: string;
  client: string;
  cabinClass: string;
  periodSeries: string;
  reportType: string;

  urlFilterParams() {
    let filterParam = '?';
    // console.log('params', this.dateFrom, this.dateTo, this.flightNumber, this.salesLocation, this.aircraft);
    
    if(this.dateFrom && this.dateFrom != ''){
      filterParam += `dateFrom=${encodeURIComponent(this.dateFrom)}`;
    }
    if(this.dateTo && this.dateTo != ''){
        // let newDateObj = moment(this.dateTo).format('YYYY-MM-DD');
        filterParam += `&dateTo=${encodeURIComponent(this.dateTo)}`;
    }
    if(this.salesLocation && this.salesLocation != ''){
        filterParam += `&salesLocation=${encodeURIComponent(this.salesLocation)}`;
    }
    if(this.user && this.user != ''){
      filterParam += `&user=${encodeURIComponent(this.user)}`;
  }
    if(this.flightNumber && this.flightNumber != ''){
        filterParam += `&flightNumber=${encodeURIComponent(this.flightNumber)}`;
    }
    if(this.cabinClass && this.cabinClass != ''){
        filterParam += `&cabinClass=${encodeURIComponent(this.cabinClass)}`;
    }
    if(this.client && this.client != ''){
      filterParam += `&client=${encodeURIComponent(this.client)}`;
    }
    if(this.periodSeries && this.periodSeries != ''){
        filterParam += `&periodSeries=${encodeURIComponent(this.periodSeries)}`;
    }else{
      filterParam += `&periodSeries=${encodeURIComponent('Monthly')}`;
    }
    if(this.reportType && this.reportType != ''){
        filterParam += `&reportType=${encodeURIComponent(this.reportType)}`; this.reportType;
    }else{
      filterParam += `&reportType=${encodeURIComponent('AllBooked')}`;
    }

    // console.log('filterparams', filterParam);
    
    return filterParam;
  }


  generatePDF(element, filename: string) {
    // console.log(element);
    const options = {
      margin: 10,
      filename: filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(options).save();
  }



  reportParams() {
    type Obj = Record<string, string>;
    let filterParam: Obj = {};
    // console.log('params', this.dateFrom, this.dateTo, this.flightNumber, this.salesLocation, this.aircraft);
    
    if(this.dateFrom && this.dateFrom != ''){
      filterParam.dateFrom = this.dateFrom;
    }
    if(this.dateTo && this.dateTo != ''){
        // let newDateObj = moment(this.dateTo).format('YYYY-MM-DD');
        filterParam.dateTo = this.dateTo;
    }
    if(this.salesLocation && this.salesLocation != ''){
        filterParam.salesLocation = this.salesLocation;
    }
    if(this.flightNumber && this.flightNumber != ''){
        filterParam.flightNumber = this.flightNumber;
    }
    if(this.cabinClass && this.cabinClass != ''){
        filterParam.cabinClass = this.cabinClass;
    }
    if(this.client && this.client != ''){
        filterParam.client = this.client;
    }
    if(this.periodSeries && this.periodSeries != ''){
        filterParam.periodSeries = this.periodSeries;
    }else{
      filterParam.periodSeries = 'Monthly';
    }
    if(this.reportType && this.reportType != ''){
        filterParam.reportType = this.reportType;
    }else{
      filterParam.reportType = 'AllBooked';
    }
    
    // console.log('filterparams', filterParam);
    
    return filterParam;
  }
}
