import { Component, OnInit } from "@angular/core";
import { CabinClassesDropdownService } from "app/main/op/cabin-classes/cabin-classes.service";
import { SaleLocationsDropdownService } from "app/main/op/sales-locations/sale-locations.service";
import { ClientsDropdownService } from "app/main/security/clients/clients.service";
import { GtoolsService } from "app/services/gtools.service";
import { isToday } from "date-fns";
import { capitalize } from "lodash";
import * as moment from "moment";
import { HqSalesReportResp, Payment } from "../models/hqreports";
import { ReportsService } from "../services/reports.service";
@Component({
    selector: "hq-sales-dashboard",
    templateUrl: "./hq-sales-dashboard.component.html",
    styleUrls: ["../reports.theme.scss", "./hq-sales-dashboard.component.scss"],
})
export class HqSalesDashboardComponent implements OnInit {
    salesReport?: HqSalesReportResp;
    salesReportSummary: any
    payments?: Payment[];
    isLoading: boolean = false;
    // view: any[]; 
    width: number = 700;
    height: number = 300;
    fitContainer: boolean = false;

    view: any[] = [1000, 400];
    // options for the chart
    showXAxis = true;
    showYAxis = true;
    gradient = true;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = "Month";
    showYAxisLabel = true;
    yAxisLabel = "Sales";
    timeline = true;
    doughnut = true;
    roundDomains = true;
    autoScale = true;
    colorScheme = {
        domain: [
            "#9370DB",
            // "#87CEFA",
            // "#FA8072",
            // "#FF7F50",
            // "#90EE90",
            // "#9370DB",
        ],
    };

    salesPieColorScheme = {
        domain: ["#5EDFC1", "#D84A59"],
    };
    //pie
    showLabels = true;
    // data goes here
    public single = [
        {
            name: "Jan",
            value: 0,
        },
        {
            name: "Feb",
            value: 0,
        },
        {
            name: "Mar",
            value: 0,
        },
        {
            name: "Apr",
            value: 0,
        },
        {
            name: "May",
            value: 0,
        },
        {
            name: "Jun",
            value: 0,
        },
        {
            name: "Jul",
            value: 0,
        },
        {
            name: "Aug",
            value: 0,
        },
        {
            name: "Sep",
            value: 0,
        },
        {
            name: "Oct",
            value: 0,
        },
        {
            name: "Nov",
            value: 0,
        },
        {
            name: "Dec",
            value: 0,
        },
    ];

    public salesPie = [
        {
            name: "Profit",
            value: 0,
        },
        {
            name: "Expenses",
            value: 0,
        },
    ];

    hqPeriodicReport;
    hqAircraftsReport = [{ "trips": 0, "pax_count": 0, "sales": 0 }]; // { "trips": 0, "pax_count": 0, "sales": 0 }
    hqFlightsReport = [{ "trips": 0, "pax_count": 0, "sales": 0 }];
    hqRoutesReport;
    hqSummaryReport;
   
    constructor(
        private reportsService: ReportsService,
        private cabinClassesDropdownService: CabinClassesDropdownService,
        private saleLocationsDropdownService: SaleLocationsDropdownService,
        private clientsDropdownService: ClientsDropdownService,
        private gTools: GtoolsService,
        // private flightNumbersDropdownService: FlightNumbersDropdownService,
    ) {
        this.saleLocationsDropdownService.onSaleLocationsDropdownChanged.subscribe((saleLocationsDropdown) => {
            // this.stations = saleLocationsDropdown;
            // console.log('hi o');
            this.stations = saleLocationsDropdown.map(saleLocationDropdown => {
                return {label: saleLocationDropdown.label, value: saleLocationDropdown.extra.id };
            });
          });
        this.cabinClassesDropdownService.onCabinClassesDropdownChanged.subscribe((cabinClassesDropdown) => {
            // this.cabinClasses = cabinClassesDropdown;
            this.cabinClasses = cabinClassesDropdown.map(cabinClassDropdown => {
                return {label: cabinClassDropdown.label, value: cabinClassDropdown.extra.id };
            });
          });
        this.clientsDropdownService.onClientsDropdownChanged.subscribe((clientsDropdown) => {
            // this.cabinClasses = cabinClassesDropdown;
            this.clients = clientsDropdown.map(clientDropdown => {
                return {label: clientDropdown.label, value: clientDropdown.extra.id };
            });
        }); 
        this.reportsService.GetFlightNumbers().subscribe((result) => {
            if(capitalize(result.message) == 'Success'){
                this.flightNumbers = result.flightNumbers;
            }
        });
        // this.reportsService.reportType = this.selectedFilter;
        this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
        this.reportsService.dateTo = moment().format('YYYY-MM-DD');
    }

    ngOnInit() {
        this.reportsService.periodSeries = this.selectedPeriod;
        this.getHqSalesReport();
        this.genAllReports();
        // console.log("Guage Chart Data2", this.flightsComparisonData);
    }

    getPeriodicSalesReport(){
        this.reportsService.GetHqPeriodicReport().subscribe((data: any) => {
            this.hqPeriodicReport = data.data;
            this.single = this.hqPeriodicReport.map(period => {
                // return new Period(period);
                if(period.date){
                    // console.log('its daily');
                    this.xAxisLabel = "Day";
                    return {name: period.date, value: period.cost};
                }else if(period.month){
                    // console.log('its monthly');
                    this.xAxisLabel = "Month";
                    return {name: period.month, value: period.cost};
                }else{
                    // console.log('its yearly');
                    this.xAxisLabel = "Year";
                    return {name: period.closing_year, value: period.cost};
                }
            });
            
            if (this.single.length > 7) {
                this.showLineChart = true;
                // if (this.hqPeriodicReport[0].date) {
                //     this.showXAxisLabel = false;
                // }else if (this.single.length > 12) {
                //     this.showXAxisLabel = false;
                // }else {
                //     this.showXAxisLabel = true;
                // }
            }else{
                this.showLineChart = false;
            }
            this.singleLineData = [
                {
                  "name": data.title,
                  "series": this.single,
                }
            ];
            // console.log("hq periodic sales", this.hqPeriodicReport);
        });
    }

    getHqSalesReport() {
        // this.flightsComparisonData = []
        // console.log("HQ Sales Report Service Works");
        this.isLoading = true;
        this.reportsService
            .GetHqSalesReport()
            .subscribe((data: HqSalesReportResp) => {
                this.salesReport = data;
                this.payments = this.salesReport.payments;
                console.log("hq sales", this.payments);
            });
        
        this.getPeriodicSalesReport();

        this.reportsService.GetHqAircraftsReport().subscribe((data: any) => {
            this.hqAircraftsReport = data.data;
            // console.log("hq aircrafts sales", this.hqAircraftsReport);
        }); 
        this.reportsService.GetHqFlightsReport().subscribe((data: any) => {
            this.hqFlightsReport = data.data;
            // console.log("hq flights sales", this.hqFlightsReport);
        }); 
        this.reportsService.GetHqRoutesReport().subscribe((data: any) => {
            this.hqRoutesReport = data.data;
            // console.log("hq routes sales", this.hqRoutesReport);
        });
        this.reportsService.GetHqSummaryReport().subscribe((data: any) => {
            this.hqSummaryReport = data.data[0];
            this.flightsComparisonData = [
                { "name": "Scheduled", "value": this.hqSummaryReport.total_scheduled_flights || 0 },
                { "name": "Cancelled", "value": this.hqSummaryReport.total_cancelled_flights || 0 },
                { "name": "Flown", "value": this.hqSummaryReport.total_flown_flights || 0 },
                { "name": "Active", "value": this.hqSummaryReport.total_active_flights || 0 },
            ];
            //   );
            // console.log("hq summary sales", this.hqSummaryReport);
        });
        this.reportsService.GetBookingsReport().subscribe((data: any) => {
            // this.hqBookingsReport = data.data;
            // console.log("hq bookings report", data);
        });
        
        this.isLoading = false;
    }

    genAllReports(){
        this.isLoading = true;
        this.reportsService.genReportSummary().subscribe((data)=> {
            this.isLoading = false;
          this.salesReportSummary = data.data[0];
          console.log(this.salesReportSummary);
          
        })
    }

    genNameAbbreviation(name:string) {
      let subName = name.split(" ");
      const nameAbbreviation = subName[0][0]+subName[1][0];
      return nameAbbreviation
    }


    dateRanges = ['Today', 'Last 7 Days', 'Last 14 Days', 'Last 30 Days', 'Custom'];
    selectedDateRange = "Today";
    onChangeDateRange(newDateRange) {
        this.selectedDateRange = newDateRange;
        if (newDateRange == 'Today') {
            this.reportsService.dateFrom = moment().format('YYYY-MM-DD');
            this.reportsService.dateTo = moment().format('YYYY-MM-DD');
        }
        if (newDateRange == 'Last 7 Days') {
            this.reportsService.dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
            this.reportsService.dateTo = moment().format('YYYY-MM-DD');
        }
        if (newDateRange == 'Last 14 Days') {
            this.reportsService.dateFrom = moment().subtract(14, 'days').format('YYYY-MM-DD');
            this.reportsService.dateTo = moment().format('YYYY-MM-DD');
        }
        if (newDateRange == 'Last 30 Days') {
            this.reportsService.dateFrom = moment().subtract(30, 'days').format('YYYY-MM-DD');
            this.reportsService.dateTo = moment().format('YYYY-MM-DD');
            console.log(newDateRange)
        }
        if (newDateRange == 'Custom') {
            return;
        }
        this.getHqSalesReport();
    }
    flightNumbers = [{'airline': 'VM', 'flight_number': '2001'}];
    selectedFlightNumber = 'All Flights';
    onChangeFlightNumber(newFlightNumber) {
        this.selectedFlightNumber = newFlightNumber;
        if (newFlightNumber == 'All Flights') {
            this.reportsService.flightNumber = null;
        }else{
            this.reportsService.flightNumber = newFlightNumber;
        }
        this.getHqSalesReport();
    }
    stations = [{label: 'Abuja Station', value: '1'}];
    selectedStation = 'All Stations';
    onChangeStation(newStation) {
        this.selectedStation = newStation;
        if (newStation == 'All Stations') {
            this.reportsService.salesLocation = null;
        }else{
            this.reportsService.salesLocation = newStation;
        }
        this.getHqSalesReport();
    }
    cabinClasses = [{label: 'Economy', value: 'Economy'}, {label: 'Business', value: 'Business'}];
    selectedCabinClass = 'All Cabin Classes';
    onChangeCabinClass(newCabinClass) {
        this.selectedCabinClass = newCabinClass;
        if (newCabinClass == 'All Cabin Classes') {
            this.reportsService.cabinClass = null;
        }else{
            this.reportsService.cabinClass = newCabinClass;
        }
        this.getHqSalesReport();
    }
    clients = [{label: 'Web', value: 'web'}, {label: 'Admin', value: 'admin'}];
    selectedClient = 'All Sale Channels';
    onChangeClient(newClient) {
        this.selectedCabinClass = newClient;
        if (newClient == 'All Sale Channels') {
            this.reportsService.client = null;
        }else{
            this.reportsService.client = newClient;
        }
        this.getHqSalesReport();
    }
    periods = ['Daily', 'Monthly', 'Yearly'];
    selectedPeriod = 'Daily';
    onChangePeriod(newPeriod) {
        this.selectedCabinClass = newPeriod;
        this.reportsService.periodSeries = newPeriod;
        this.getPeriodicSalesReport();
    }
    downloadOptions = [{label: 'Download Excel Sheet', value: 'xls'}, 
                    // {label: 'Download CSV Sheet', value: 'csv'}, {label: 'Download PDF Sheet', value: 'pdf'}
                    ];
    selectedDownloadOption = 'share';
    onChangeDownloadOption(newDownloadOption) {
        this.selectedStation = newDownloadOption;
        if (newDownloadOption == 'xls') {
          this.gTools.exportToExcel('Baggage Reports', 'xlsx');
        }else{
          
        }
    }
    
    getTotal(arr: any[], index: string){
        let sum = 0;
        arr.forEach(element => {
            sum += Number(element[index]);
        });
        return sum;
    }


     // guage chart data
     flightsComparisonData = [
        { "name": "Scheduled", "value": 0 },
        { "name": "Cancelled", "value": 0 },
        { "name": "Flown", "value": 0 },
        { "name": "Active", "value": 0 }
     ];
     flightsComparisonView: any[] = [400, 300];
     flightsComparisonLegend: boolean = true;
     flightsComparisonLegendPosition: string = 'right';
 
     flightsComparisonColorScheme = {
         domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5']
     };

    // format for line chart
    singleLineData = [
        {
          "name": "Sales",
          "series": this.single,
        }
    ];
    showLineChart;
    // singleLineData: any[];
    // public view: any[] = [700, 400];
    // public showXAxis = true;
    // public showYAxis = true;
    // public gradient = false;
    // public showLegend = true;
    // public showXAxisLabel = true;
    // public xAxisLabel: "Years";
    // public showYAxisLabel = true;
    // public yAxisLabel: "Salary";
    // public graphDataChart: any[];
    // public colorScheme = {
    //     domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    // };

    onSelect($event){}
    onActivate($event){}
    onDeactivate($event){}
}
