import { Injectable } from '@angular/core';
import * as html2pdf from 'html2pdf.js';

let html2pdf: any;
@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {
  constructor() {}

  generatePDF(element, filename: string) {
    // console.log(element);
    const options = {
      margin: 10,
      filename: filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(options).save();
  }










}





