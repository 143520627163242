import { FuseUtils } from '@fuse/utils';

export class Client
{
    iri: string;
    iriType: string;
    id: number;
    name: string;
    redirectUris: string[];
    allowedGrantTypes: string[];
    publicId: string;
    secret: string;

    /**
     * Constructor
     *
     * @param client
     */
    constructor(client?)
    {
        client = client || {};
        this.iri        = client["@id"] || null;
        this.iriType    = client["@type"] || null;
        this.id = client.id || null;;
        this.name = client.name || null;
        this.redirectUris = client.redirect_uris || [];
        this.allowedGrantTypes = client.allowed_grant_types || [];
        this.publicId = client.public_id || null;
        this.secret = client.secret || null;
    }
}
