import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { Response } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { interval, throwError as observableThrowError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

// import { environment } from '../../environments/environment.prod';
import { environment } from '../../environments/environment';
import { secureStorage } from './storage';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { navigation } from 'app/navigation/navigation';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
// import { ApiService } from './api.service';
// import { promise } from 'protractor';
// import { Promise } from 'ag-grid-community';s


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authURL = environment.serverURL + environment.authURI;

  isLoggedIn = false;
  autoLogoutTimer: NodeJS.Timer;

  // store the URL so we can redirect after logging in
  private redirectUrl: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    // private _api: ApiService,
    private navigationService: FuseNavigationService,
    private dialog: MatDialog,
  ) { }

  login(username: string, password: string): any {

    const httpHeaders = new HttpHeaders();
    // httpHeaders.append('content-type', 'application/x-www-form-urlencoded');
    httpHeaders.append('content-type', 'application/form-data');
    httpHeaders.append('Access-Control-Allow-Origin', environment.serverURL);
    httpHeaders.append('Access-Control-Allow-Method', 'POST');
    // Request headers you wish to allow
    httpHeaders.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    // let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', environment.PRIMAL_CLIENT_ID);
    body.set('client_secret', environment.PRIMAL_CLIENT_SECRET);
    body.set('grant_type', 'password');

    return this.httpClient.post(this.authURL, body, options).pipe(map(response => {

      // console.log('login-response',response);
      // login successful if there's an access_token in the response
      if (response && response['access_token']) {

        response['username'] = username;
        response['expires_at'] = moment().add(response['expires_in'],'seconds').format('YYYY-MM-DD HH:mm:ss');

        // console.log(response,moment().toLocaleString(), response['expires_at'],moment().format('YYYY-MM-DD HH:mm:ss'));
        // store user details and access_token in local storage to keep user logged in between page refreshes
        secureStorage.saveCurrentUser(response);

        this.isLoggedIn = true;

        this.startTokenExpirationCountdown();

        // TODO: reload navigation items for permissions

        if (this.isNewUser()){
          // redirect to reset password
          // /pages/auth/reset-password-2

        }
      }
      return response;
    })
    );
  }

  refreshToken(): any {
    
    const refreshToken = secureStorage.getRefreshToken();
    if(!refreshToken) return;

    const httpHeaders = new HttpHeaders();
    // httpHeaders.append('content-type', 'application/x-www-form-urlencoded');
    httpHeaders.append('content-type', 'application/form-data');
    httpHeaders.append('Access-Control-Allow-Origin', environment.serverURL);
    httpHeaders.append('Access-Control-Allow-Method', 'POST');
    // Request headers you wish to allow
    httpHeaders.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    const options = {
      headers: httpHeaders
    };

    const body = new FormData();
    // let body = new URLSearchParams();
    body.set('refresh_token', refreshToken);
    body.set('client_id', environment.PRIMAL_CLIENT_ID);
    body.set('client_secret', environment.PRIMAL_CLIENT_SECRET);
    body.set('grant_type', 'refresh_token');

    return this.httpClient.post(this.authURL, body, options).pipe(map(response => {

      // console.log('login-response',response);
      // login successful if there's an access_token in the response
      if (response && response['access_token']) {

        response['username'] = secureStorage.getUsername(); //username;
        response['expires_at'] = moment().add(response['expires_in'],'seconds').format('YYYY-MM-DD HH:mm:ss');

        // console.log(response,moment().toLocaleString(), response['expires_at'],moment().format('YYYY-MM-DD HH:mm:ss'));
        // store user details and access_token in local storage to keep user logged in between page refreshes
        secureStorage.saveCurrentUser(response);

        this.isLoggedIn = true;

        this.startTokenExpirationCountdown();

        // TODO: reload navigation items for permissions

        // if (this.isNewUser()){
        //   // redirect to reset password
        //   // /pages/auth/reset-password-2

        // }
      }
      return response;
    })
    );
  }

  logout(): void {
    // remove user from local storage to log user out
    secureStorage.clearLoginCredentials();

    //close all dialogs
    this.dialog.closeAll();

    // Clear any auto-logout timers
    if (this.autoLogoutTimer) {
      clearTimeout(this.autoLogoutTimer);
    }

    this.isLoggedIn = false;
    // clear token remove user from local storage to log user out
    // this.cookieService.deleteAll('/');
    // todo: implement Server side logout for improved security

    this.router.navigate(['pages/auth/login-2']);
  }

  startTokenExpirationCountdown(){
    if(!this.isAuthenticated() ){ //|| this.isTokenExpired()){
      // dont logout if on unguarded pages like auth
      // this.logout();
      return;
    }

    let expiresAt = secureStorage.getTokenExpiresAt();
    let expiresIn = moment(expiresAt,'YYYY-MM-DD\THH:mm:ss').diff(moment().format('YYYY-MM-DD\THH:mm:ss'),'milliseconds');
    // console.log(moment(expiresAt,'YYYY-MM-DD\THH:mm:ss').fromNow())
    // set autologout
    this.autoLogoutTimer = setTimeout(() => {
      // console.log('Token Expired')
      // this.logout();

      this.showExtendSessionDialog();


    }, expiresIn);
  }

  showExtendSessionDialog(){

    let duration = 30;
    let timeLeft = duration;
    let _autoLogoutTimer = setTimeout(() => {
        clearTimeout(_autoLogoutTimer);
        intervalTimer.unsubscribe();
        this.logout();
    },duration*1000);

    let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: true
    });
    confirmDialogRef.componentInstance.confirmMessage = `Your Session Would Expire in ${timeLeft} Seconds. Confirm to extend?`;
    
    let intervalTimer = interval(1000).subscribe(_=>{
      timeLeft-=1
      if(timeLeft >0 && confirmDialogRef.componentInstance!==null)
        confirmDialogRef.componentInstance.confirmMessage = `Your Session Would Expire in ${timeLeft} Seconds. Confirm to extend?`;
      else
        intervalTimer.unsubscribe();
    })

    confirmDialogRef.afterClosed().subscribe(result => {
        clearTimeout(_autoLogoutTimer);
        intervalTimer.unsubscribe();
        if ( result )
        {
            this.refreshToken().subscribe(result =>{})
        }else{
            this.logout();
        }
    });

  }

  public getToken(): string {
    return secureStorage.getAccessToken();
  }

  public resetPasswordx(data: any): any {
    return this.httpClient.post(environment.serverURL + '/auth/reset-password', data).pipe(map((response: any) => {

        // console.log('password-reset-msg',response.message);

        return response;
      })
    );
  }

  /**
   * Load logged user permissions
   *
   * @returns {Promise<any>}
   */
  resetPassword(data: any): Promise<any>
  {
      return new Promise((resolve, reject) => {
              this.httpClient.post(environment.serverURL + '/api/auth/reset-password', data)
                  .subscribe((response: any) => {
                    // console.log(response.message);
                      resolve(response);
                  }, reject);
          }
      );
  }

  /**
   * Load logged user permissions
   *
   * @returns {Promise<any>}
   */
  resetPasswordPublic(data: any): Promise<any>
  {
      return new Promise((resolve, reject) => {
              this.httpClient.post(environment.serverURL + '/auth/reset-password', data)
                  .subscribe((response: any) => {
                    // console.log(response.message);
                      resolve(response);
                  }, reject);
          }
      );
  }

  /**
   * Load logged user permissions
   *
   * @returns {Promise<any>}
   */
  forgotPassword(data: any): Promise<any>
  {
      return new Promise((resolve, reject) => {
          this.httpClient.post(environment.serverURL + '/auth/forgot-password', data)
              .subscribe((response: any) => {
                  // console.log(response.message);
                  resolve(response);
              }, reject);
          }
      );
  }
  
  public isNewUser(): boolean {
    return false;
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting 
    if (!token)  return false;
    
    return !this.isTokenExpired();
  }

  public isTokenExpired(): boolean {
    const token = this.getToken();
    if(!token) return true;
    // get the token
    const expires_at = secureStorage.getTokenExpiresAt();
    return moment() >= moment(expires_at,'YYYY-MM-DD\THH:mm:ss');
  }

  private handelError(error: Response): any {
    // return new ErrorObservable(error.json() || 'server error');
    return observableThrowError(error.json() || 'server error');

  }

  public getRedirectUrl(): string{
    return this.redirectUrl;
  }
  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  public getUsername(): string {
    return secureStorage.getUsername();
  }
  public user(): string {
    return secureStorage.getUserData();
  }
}
